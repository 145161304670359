import React, { useState, useEffect, useRef } from 'react';
import { Progress, useToast, Box, Text, Flex, Button, Input, InputGroup, Spinner, InputRightElement, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { useAuth } from '../auth/AuthContext'; // Adjust the path as needed
import Cookies from 'js-cookie';

const DisplayNameComponent = () => {
  const [displayName, setDisplayName] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure(); // For modal control
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state
  const [isSaving, setIsSaving] = useState(false); // Renamed for clarity
 
  const toast = useToast();
  const maxDisplayNameLength = 20;
  const inputRef = useRef(null);
  const navigate = useNavigate(); // For programmatic navigation
  const { authState } = useAuth();
  // const userId = authState.userId; // Get userId from authentication state

  
  
    const handleInputChange = (e) => {
      const newName = e.target.value;
      if (newName.length <= maxDisplayNameLength) {
        setDisplayName(newName);
      }
    };

      const handleNavigate = () => {
        navigate('/completeOnboardingQuestions'); // Navigate when 'Let's Go!' is clicked
      };
    
      const handleSubmit = async () => {
        if (!displayName) {
          toast({
            title: 'Error',
            description: "Display name cannot be empty.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      
        setIsLoading(true); // Start loading
        setIsSaving(true);
        
        const userId = Cookies.get('userId')
        try {
          // const userId = authState.userId; 
          // Get userId from authState
          
          if (!userId) {
            throw new Error("User ID not found. Please log in again.");
          }
      
          const response = await fetch(`/api/user/updateDisplayName`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, displayName }),
          });
      
          setIsLoading(false); // End loading
          console.log("resposnee", response);
          if (!response.ok) {
            throw new Error('Failed to update display name.');
          }

          setIsLoading(true); // Show spinner
          setTimeout(() => {
            setIsLoading(false); // Hide spinner after 1 second
            navigate('/completeOnboardingQuestions');
          }, 350);
          } catch (error) {
          setTimeout(() => {
            setIsLoading(false); // End loading
            toast({
              title: 'Error',
              description: error.message || "There was an error updating your display name.",
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }, 500);
          }
        };


      return (
        authState.isAuthenticated &&
        <Box bg="#1C1C1C" minHeight="100vh">
          {/* Header and Welcome Text */}
          <Box textAlign="center" mb={4} borderBottom="0.5px solid #A0A0A0" pt={8}>
            <Text fontSize={{ base: "2xl", md: "xl", lg: "3xl" }} fontWeight="bold" color="white" letterSpacing="-3px" mt={0} mb={2}>
              dots
            </Text>
          </Box>
          {/* Display Name Input Box */}
          <Flex
            direction="column"
            width="100%"
            maxW={{ base: "100%", md: "md", lg: "lg", xl: "xl" }} // Responsive maxWidth
            mx="auto" // Centering content
            mt={4}
            px={4}
          >
            {/* Display Name Input Box */}
                  <Flex
                    direction="column"
                    width="100%"
                    maxW={{ base: "100%", md: "md", lg: "lg", xl: "xl" }} // Responsive maxWidth
                    mx="auto" // Centering content
                    mt={4}
                    px={4}
                  >
                    {/* Text Elements */}
                    <Box width={{ base: "100%", md: "100%", lg: "100%" }} px={{ base: 1, md: 4, lg: 6 }} pb={4}>
                      <Text fontSize={{ base: "2xl", md: "xl", lg: "3xl" }} fontWeight="bold" color="white" textAlign="left" mt={4}>
                        What's your name?
                      </Text>
                      <Text fontSize={{ base: "md", md: "md", lg: "lg" }} color="white" mb={4} textAlign="left">
                        Personalize your Dot.
                      </Text>
                    </Box>

                    {/* Input Group Box Container */}
                  {/* Input Group Box Container */}
                  <Box width={{ base: "100%", md: "100%", lg: "100%" }} mb={4} px={{ base: 1, md: 4, lg: 6 }}>
                    <InputGroup size="lg" alignItems="center"> {/* Add alignItems here */}
                      <Input
                        ref={inputRef}
                        placeholder="Full name"
                        value={displayName}
                        onChange={handleInputChange}
                        display="flex"
                        bg="white"
                        color="black"
                        borderColor="transparent"
                        borderRadius="full"
                        _placeholder={{ color: 'black' }}
                        fontSize={{ base: "16px", md: "md", lg: "lg" }}
                        height={{ base: "52px", md: "56px", lg: "64px" }} // Responsive height
                        pl={6} // Padding-left added here
                        fontWeight="light"
                      />
                    </InputGroup> 



                  </Box>
                  {/* Button Container */}
                  <Box width={{ base: "100%", md: "100%", lg: "100%" }} px={{ base: 1, md: 4, lg: 6 }}>
                      <Button
                        onClick={handleSubmit}
                        //onClick={handleNavigate}
                        bg="#FF0050"
                        color="white"
                        fontWeight="bold"
                        fontSize={{ base: "md", md: "md", lg: "lg" }}
                        _hover={{ bg: "#ef004b" }}
                        borderRadius="full"
                        width="100%"
                        height={{ base: "48px", md: "56px", lg: "64px" }}
                        isLoading={isLoading} // Chakra UI's Button prop to handle loading state
                        //loadingText="Loading" // Optional: Text to display when loading
                      >
                        Next
                      </Button>
                    </Box>
                  </Flex>
        </Flex>
        <Modal isOpen={isOpen} closeOnOverlayClick={false} >
                    <ModalOverlay />
                    <ModalOverlay
                        bg="blackAlpha.900" // Adjust the background color and opacity
                      />
                   <ModalOverlay />
                    <ModalContent
                      bg="#1C1C1C"
                      //width="90vw"  // 100% of viewport width
                      //height="70vh" // 100% of viewport height
                      maxW="800px"
                      mx="auto"
                      borderRadius="xl" // This will make the borders rounder
                      //boxShadow="0px 0px 5px 5px black" // Custom white shadow
                      p={{ base: 2, md: 4, lg: 4 }}
                    >
                      {/* Centralized Container */}
              <ModalBody color="white" mt={6}>
              <Text textAlign="left" fontWeight="bold" lineHeight="shorter" mb={{ base: 5, md: 5, lg: 5 }} fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
                    Welcome <span style={{ color: '#FF0050' }}>{displayName}</span>!
                  </Text>
                  <Text textAlign="left" fontSize={{ base: "md", md: "md", lg: "xl" }} lineHeight="shorter" mb={{ base: 4, md: 4, lg: 5 }}> {/* Adjust bottom margin */}
                    Our mission at Dots is to be the fastest path to information.
                  </Text>
                  {/* Remove <br /> tags */}
                  <Text textAlign="left" fontSize={{ base: "md", md: "md", lg: "xl" }} lineHeight="shorter" mb={{ base: 4, md: 4, lg: 5 }}>
                    To get started, fill out a few questions to complete your Dot.
                  </Text>
                  <Text textAlign="left" fontSize={{ base: "md", md: "md", lg: "xl" }} lineHeight="
                  shorter" mb={{ base: 4, md: 4, lg: 5 }}>
                  The more information you provide, the better your answers and recommendations will be.
                  </Text>
                  <Text textAlign="left" fontSize={{ base: "md", md: "md", lg: "xl" }} lineHeight="shorter" mb={{ base: 3, md: 3, lg: 2 }}>
                  Once doing so, you will get access to:
                  </Text>
                  <style>
                  {`
                      .responsive-list {
                          list-style-position: outside;
                          padding-left: 2em;
                          text-align: left;
                      }
                    
                      .responsive-list li {
                          font-size: 1rem; /* Equivalent to 'md' */
                          line-height: 1.2; /* A shorter line height */
                          margin-bottom: 1em;
                      }
                    
                      @media (min-width: 768px) {
                          .responsive-list li {
                              font-size: 1.25rem; /* Equivalent to 'xl' */
                              line-height: 1.2; /* A slightly larger line height for readability */
                              margin-bottom: 1em;
                          }
                      }
                  `}
              </style>
                    
              <ol className="responsive-list">
                  <li>
                  A custom link you can share with friends for instant Q&A
                  </li>
                  <li>
                  Personalized recommendations!
                  </li>
                  <li>
                  Unlimited questions on any Dot profile
                  </li>
              </ol>
                </ModalBody>
                <ModalFooter justifyContent="center">
              <Button
                onClick={handleNavigate}
                bg="#FF0050"
                color="white"
                fontWeight="bold"
                _hover={{ bg: "#ef004b" }}
                size="lg"     // Make the button larger
                width={{ base: "100%", md: "100%", lg: "90%" }}   // Increase the width of the button
                mt={0}
                mb={4}
                borderRadius="full"
                height={{ base: "50px", md: "56px", lg: "68px" }}
                fontSize={{ base: "md", md: "md", lg: "xl" }}
              >
                Let's Go!
              </Button>
            </ModalFooter>
              </ModalContent>
            </Modal>
    </Box>
  );
};
    

export default DisplayNameComponent;
