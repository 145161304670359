import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, Tabs, Spacer, TabList, TabPanels, Tab, TabPanel, TabIndicator, Flex, Text, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../auth/firebaseConfig';
import Cookies from 'js-cookie';
import Notes from './Notes';
import Inbox from './Inbox';
import Ask from './Ask';
import AskLink from './AskLink';
import { useAuth } from '../auth/AuthContext';
import CustomIcon from '../../public/assets/menuButtonCircles.svg';




function AppContent() {
  const { authState, setAuthInfo } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const [hasNewInboxItems, setHasNewInboxItems] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fromOnboarding = location.state?.fromOnboarding || false;
  const [prefilledQuestion, setPrefilledQuestion] = useState("");
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");

  //useEffect(() => {
  //  console.log('user signned in', authState)
  //}, [authState])

  //Auto-ask first time users a question after the onboarding
  useEffect(() => {
    const completedOnboarding = sessionStorage.getItem('completedOnboarding');
    if (completedOnboarding) {
      // Set your default question for first-time users
      //setPrefilledQuestion("i want you to give me: 1) Emoji: with my emoji 2) MBTI: my MBIT 3) Enneagram: my enneagram 4) Spirit Animal: my spirit animal and 5) Recommendation: An obscure recommendation (movie, song, book, TV) that i may enjoy with the year and content type. only provide me the concise answer with google link and a quick reason why in 10 words or less in one column");
      setPrefilledQuestion("give me a quick summary about yourself.");
      sessionStorage.removeItem('completedOnboarding'); // Clear the flag
    }
  }, []); // Empty dependency array to run only once on component mount

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const updateInboxNotification = (hasNewItems) => {
    setHasNewInboxItems(hasNewItems);
  };

// Function to fetch user details including uniqId
const fetchUserDetails = async (userId) => {
  try {
    const response = await fetch(`/api/getUserDetails`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId })
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Returns the entire user object including uniqId
    } else {
      throw new Error("Failed to fetch user details");
    }
  } catch (error) {
    console.error("Error fetching user details:", error);
    return null;
  }
};

// Function to navigate to the user's AskLink page when userId is in URL
//const handleMyBioLinkClick = () => {
//  const userId = localStorage.getItem('userId');
//  if (userId) {
//    navigate(`/user/${userId}`);
//  } else {
//    // Handle the case where there is no user ID (e.g., show an error or redirect to a sign-in page)
//  }
//};

//const handleMyBioLinkClick = async () => {
//  if (authState.userId) {
//    const userDetails = await fetchUserDetails(authState.userId);
//    if (userDetails && userDetails.uniqId) {
//      navigate(`/${userDetails.uniqId}`);
//    } else {
//      // Handle errors or missing details
//    }
//  } else {
//    // Handle case where user is not logged in
//  }
//};

//updated to use cookies:
const handleMyBioLinkClick = async () => {
  const userId = Cookies.get('userId'); // Get userId from cookies
  if (userId) {
    const userDetails = await fetchUserDetails(userId);
    if (userDetails && userDetails.uniqId) {
      navigate(`/${userDetails.uniqId}`);
    } else {
      // Handle errors or missing details
    }
  } else {
    // Handle case where user is not logged in
  }
};


const handleSignOut = async () => {
  try {
    await signOut(auth);
    setAuthInfo({ userId: null });
    navigate('/');
  } catch (error) {
    console.error("Sign out error:", error);
  }
};


return (
  authState.isAuthenticated && 
  <div style={{ backgroundColor: "#1C1C1C", paddingTop: "1rem" }}> {/* Set the background color and top padding */}
    {/* Header with Menu Button */}
    <Flex justifyContent="space-between" alignItems="center" p={3}>

      {/* Invisible Spacer */}
      <Box width="40px" height="40px" visibility="hidden" /> {/* This box is for balancing the layout */}

      {/* Dots Text Centered */}
      <Flex flexGrow={1} justifyContent="center"> {/* This Flex container will center the content */}
        <Text fontSize="3xl" fontWeight="bold" letterSpacing="-3px" color="white">
          dots
        </Text>
      </Flex>

      {/* Menu Button */}
      <Menu>
        <MenuButton
          className="menu-button-custom"
          style={{ marginRight: isLargerThanMD ? '0px' : '0' }}
          as={IconButton}
          aria-label="Options"
          //icon={<HamburgerIcon color="white"/>}
          icon={<img src={CustomIcon} alt="Custom Icon" />}
          variant="outline"
          size="lg"
          borderColor="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent", borderColor: "transparent" }}
          _focus={{ boxShadow: "none" }}
        />
        <MenuList zIndex={10}>
          <MenuItem as={Link} to="/">
            Home
          </MenuItem>
          <MenuItem onClick={handleMyBioLinkClick}>
            My Bio Link
          </MenuItem>
          {/* <MenuItem as={Link} to="/">
            Landing Page
          </MenuItem> */}
          <MenuItem onClick={handleSignOut} color="#FF0050">
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
       {/* Invisible Spacer */}
    </Flex>

    <Tabs variant='unstyled' color='white' index={tabIndex} onChange={handleTabsChange}>
  <Flex justify="center">
    <TabList mb={2}>
      {/* Increased margin and font size for each tab */}
      <Tab _focus={{ outline: 'none', boxShadow: 'none' }} style={{ margin: '0 15px'}} fontSize= {{base: "md", md:"md", lg: "lg" }} _selected={{ color: "white" }}>Ask</Tab>
      <Tab _focus={{ outline: 'none', boxShadow: 'none' }} style={{ margin: '0 15px'}} fontSize= {{base: "md", md:"md", lg: "lg" }} _selected={{ color: "white" }}>Notes</Tab>
      <Tab _focus={{ outline: 'none', boxShadow: 'none' }} style={{ margin: '0 15px'}} fontSize= {{base: "md", md:"md", lg: "lg" }} _selected={{ color: "white" }}>
        <Box position="relative" display="inline-block">
          Inbox
          {hasNewInboxItems && (
            <Box
              position="absolute"
              top="3px"
              right="-10px"
              height="6px"
              width="6px"
              backgroundColor="#FF0050"
              borderRadius="50%"
            />
          )}
        </Box>
      </Tab>
    </TabList>
  </Flex>

        <TabIndicator
          mt="-15px"
          height="4px"
          bg="#FF0050"
          // borderRadius="6px"
          sx={{ width: '10px' }} // Adjust the width as needed
        />

        <TabPanels>
          <TabPanel>
            <Ask prefillQuestion={prefilledQuestion} />
          </TabPanel>
          <TabPanel  p={0} width="full">
            <Notes />
          </TabPanel>
          <TabPanel p={0} width="full">
            <Inbox onNavigate={handleTabsChange} onUpdateNotification={updateInboxNotification} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}

export default AppContent;
