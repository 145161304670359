import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Text, Textarea, Button, useToast, Flex, Image,
    Modal, Link as ChakraLink, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody, Input, useDisclosure, Spacer } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { auth } from "../auth/firebaseConfig";
import withFirebaseAuth from "../utils/with_firebase_auth";
import { useNavigate } from 'react-router-dom';

const FeedbackPage = () => {
  const { askId } = useParams();
  console.log('askId:', askId);
  const [feedback, setFeedback] = useState('');
  const [questionAndResponse, setQuestionAndResponse] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const userId = Cookies.get('userId') // Get userId from authentication state
  const navigate = useNavigate();
  const toast = useToast(); // Using Chakra UI's useToast for feedback messages
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this line
  const [localNotes, setLocalNotes] = useState([]);
  const [cardFeedback, setCardFeedback] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');

  //useEffect(() => {
  //  onOpen(); // Attempt to open the modal immediately for testing
  //}, [onOpen]);

  useEffect(() => {
    const checkAndOpenModal = async () => {
        const userId = await Cookies.get('userId');
        console.log('Checking user ID for modal:', userId); // Diagnostic log
        if (!userId) {
            console.log('Opening modal because userId is not present.'); // Diagnostic log
            onOpen();
        }
    };

    checkAndOpenModal();
}, []); // Depending on your setup, you might need to adjust this dependency array.

  // If user is not signed in, show the sign-in modal
  const handleSignIn = async () => {
    if (!email) {
      toast({
        title: "Please enter an email address.",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);
    try {
      await sendSignInLinkToEmail(auth, email, {
        url: `${window.location.origin}/ask/${askId}`, // Redirect back to this page
        handleCodeInApp: true,
      });
      Cookies.set('emailForSignIn', email, { expires: 1 }); // Store the email in a cookie for later
      toast({
        title: "Check your email",
        description: "A sign-in link has been sent to your email address.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      onClose(); // Optionally close the modal here or keep it open with a message
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };


    useEffect(() => {
        const fetchQuestion = async () => {
          setIsLoading(true);
          try {
            if (!userId) {
              console.error("No userId found");
              setIsLoading(false);
              return;
            }
      
            const fetchWithAuth = withFirebaseAuth(fetch);
            const response = await fetchWithAuth(`/api/question/${askId}`);
            if (response.ok) {
              const question = await response.json();
              console.log("Fetched askeeUserId:", question.askeeUserId);
              console.log("Local userId:", Cookies.get('userId'));
      
              // Now directly use the fetched data for comparison
              const isAuthorized = question.askeeUserId === Cookies.get('userId');
              console.log("Is user authorized to submit feedback:", isAuthorized);
      
              setQuestionAndResponse({
                askId: question.askId,
                receiver_userId: question.askeeUserId, // Ensure this matches your backend structure
                content_title: question.askText,
                content_response: question.answerText,
                asker_user_id: question.askerUserId,
                asker_displayName: question.askerDisplayName,
                asker_profilePic: question.askerProfilePic,
              });
      
              // Set canSubmit based on the current fetched question
              setCanSubmit(isAuthorized);
            } else {
              console.error("Failed to fetch the question");
            }
          } catch (error) {
            console.error("Error fetching the question:", error);
          } finally {
            setIsLoading(false);
          }
        };
      
        if (userId && askId) {
          fetchQuestion();
        }
      }, [userId, askId]); // Depend on `userId` and `askId`
      
      

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
  setIsSubmitting(true);

  if (!userId || !canSubmit) {
    console.error("User is not authenticated or not authorized");
    setIsSubmitting(false);
    toast({
      title: "Error",
      description: "You must be logged in and authorized to submit feedback.",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "bottom"
    });
    return;
  }

  const fullFeedbackContent = `Question: ${questionAndResponse.content_title} My Feedback: ${feedback}`;
  const askerQuestion = `${questionAndResponse.content_title}`;
  const askeeFeedback = `${feedback}`;

  const feedbackNote = {
    userId: userId,
    relatedAskId: askId,
    content: fullFeedbackContent,
    embedding: null,
    embeddingModel: null,
    tokenCount: null,
    tokenCost: 0,
    status: "ENABLED",
    isArchived: false,
    createdAt: new Date().toISOString(),
    noteType: "FEEDBACK",
    updatedAt: new Date().toISOString(),
  };

  try {
    const fetchWithAuth = withFirebaseAuth(fetch);

    // Submit feedback
    const feedbackResponse = await fetchWithAuth(`/api/feedback/${askId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ feedbackText: fullFeedbackContent, askersQuestion: askerQuestion, askeesFeedback: askeeFeedback }),
    });

    if (!feedbackResponse.ok) {
      throw new Error("Failed to submit feedback");
    }

    // Submit the note
    const noteResponse = await fetchWithAuth(`/api/notes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(feedbackNote),
    });

    if (!noteResponse.ok) {
      throw new Error("Failed to create note");
    }

    const responseData = await noteResponse.json();
    const addedNote = { ...feedbackNote, noteId: responseData.noteId };
    setLocalNotes((prevNotes) => [...prevNotes, addedNote]);
    setCardFeedback((prev) => ({ ...prev, [askId]: true }));

    toast({
        title: "Feedback Submitted",
        description: "Your feedback has been successfully submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom"
      });
  
      // Navigate to a different page after successful submission, e.g., back to the home page or a confirmation page
      navigate('/'); // Adjust the path as needed
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Error",
        description: "There was an issue submitting your feedback.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom"
      });
    } finally {
      setFeedback(""); // Reset the feedback field
      setIsSubmitting(false); // Indicate that submission has finished
    }
  };


  if (!questionAndResponse) 
  return <Box>Loading...</Box>;

  const Header = () => {
    const userId = Cookies.get('userId'); // Get userId from cookies
    const isLoggedIn = userId !== undefined; // Check if the user is logged in based on userId presence
      
    return (
      <Flex
        alignItems="center"
        p={4}
        bg="#1C1C1C"
        borderBottom="1px"
        borderColor="#5C5C5C"
        // Adjust padding as needed or use a different way to set side padding if required
        px={{ base: 4, md: 8 }}
      >
        <Spacer />
        <Text fontSize="3xl" fontWeight="bold" letterSpacing="-3px" color="white" position="absolute" left="50%" transform="translateX(-50%)">
          dots
        </Text>
        <Spacer />
        <Button 
          as={Link} 
          to={isLoggedIn ? "/" : "/signup"} 
          colorScheme="pink" 
          backgroundColor="white" 
          textColor="#FF0050" 
          borderRadius="full"
          _hover={{ bg: 'white', textColor: "#FF0050" }}>
          {isLoggedIn ? "Home" : "Sign Up"}
        </Button>
      </Flex>
    );
  };

  return (
    <>
    <Header />
    <Box  p={4} color="white" px={{ base: 4, md: 8 }}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sign in to continue</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={4}>Enter your email to receive a sign-in link:</Text>
            <Input
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              mt={4}
              colorScheme="blue"
              isLoading={isLoading}
              onClick={handleSignIn}
            >
              Send Sign-In Link
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box maxW={{ base: "100%", md: "70%", lg: "40%" }} mx="auto">
        {/* New Header with Asker's Profile Picture and Name */}
        <Flex alignItems="center" mb={4}>
          <Image
            borderRadius="full"
            boxSize="40px" // Adjust size as needed
            src={questionAndResponse?.asker_profilePic}
            alt="Asker's Profile"
            mr={2}
          />
          <Text fontSize="xl" fontWeight="bold" color="white">
            <Text as="span" color="#FF0050">
              {questionAndResponse?.asker_displayName}
            </Text>
            {' '}asked:
        </Text>
        </Flex>
        {/* External Ask Tag */}

        {/* Content Title and Response */}
        <Text textAlign="left" fontSize={{base: "3xl", md: "2xl", lg: "3xl"}} lineHeight="1.0" fontWeight="bold" mb={4} color="white">
          {questionAndResponse?.content_title}
        </Text>

        <Text textAlign="left" fontSize={{ base: "md", md: "md", lg: "lg" }} color="#FF0050" mb={0}>
          Your dot answered:
        </Text>
        <Box textAlign="left" className="markdown-content" fontSize={{ base: "md", md: "md", lg: "lg" }} mb={6} color="white">
          {/* Use Markdown component if response includes Markdown content */}
          {/* <Markdown>{questionAndResponse?.content_response}</Markdown> */}
          {questionAndResponse?.content_response}
        </Box>

        {/* Feedback Box */}
        {canSubmit ? (
          <Box borderWidth="1px" borderRadius="10px" overflow="hidden" borderColor="white" mt={4}>
            <Box backgroundColor="white" textAlign="left" p={3}>
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight="600" color="black">
                Not happy with the response? Add feedback:
              </Text>
            </Box>
            <Box padding={3}>
              <Textarea
                placeholder="Type your feedback here..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                maxLength={500}
                resize="none"
                height="100px"
                fontSize={{ base: "md", md: "md", lg: "lg" }}
                color="white"
                border="none"
              />
              <Flex justifyContent="flex-end" alignItems="center" mt={4}>
                <Text mr={2} color="white">{feedback.length} / 500</Text>
                <Button
                  onClick={handleSubmitFeedback}
                  isLoading={isSubmitting}
                  colorScheme="red"
                  bg="#FF0050"
                  color="white"
                  fontWeight="bold"
                >
                  Submit
                </Button>
              </Flex>
            </Box>
          </Box>
        ) : (
          <Text>You are not authorized to provide feedback for this question.</Text>
        )}
      </Box>
      </Box>
    </>
  );
};

export default FeedbackPage;
