import { useState, useRef, useEffect } from "react";
import Markdown from "react-markdown";
import { Input, Icon, Avatar, useMediaQuery, Button, Flex, Box, Text, Spinner, Image, IconButton, Textarea, InputRightElement, InputGroup, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useToast } from "@chakra-ui/react";
import React from "react";
import { AutoResizeTextarea } from './AutoResizeTextarea'; // Import your custom component
import { auth } from "../auth/firebaseConfig";
import Compressor from 'compressorjs';
import heic2any from "heic2any";
import Cookies from 'js-cookie';
import { useAuth } from '../auth/AuthContext'; // Adjust the path as needed
import ShareIcon from "../assets/share_icon.svg";
import ShareIcon2 from "../assets/share_window_arrow.svg"

const AskComponent = ({ prefillQuestion }) => {
  const { authState } = useAuth();
  const userId = Cookies.get('userId') // Get userId from authentication state
  // const userId = authState.userId; // Get userId from authentication state
  console.log("Received userId in AskComponent:", userId);
  const [query, setQuery] = useState("");
  const [response, setResponse] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState(null);
  const fetchController = useRef(new AbortController());
  const [profilePic, setProfilePic] = useState<string | null>(null);
  const defaultProfilePic = '/path/to/default/profile/pic.jpg'; // Path to your default profile picture
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [imageUploadError, setImageUploadError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // The original image selected for cropping
  const [croppedImage, setCroppedImage] = useState(null); // The output from the crop component
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isAskingQuestion, setIsAskingQuestion] = useState(false);
  const toast = useToast();
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const [displayName, setDisplayName] = useState('');
  const [uniqId, setUniqId] = useState('');
  const [askId, setAskId] = useState(null); // Initial state is null indicating no askId is available yet
  const [streamCompleted, setStreamCompleted] = useState(false);



  useEffect(() => {
    const cachedResponse = localStorage.getItem('cachedResponse');
    const cachedAskId = localStorage.getItem('cachedAskId'); // Retrieve cached askId
    if (cachedResponse) {
      setResponse(cachedResponse);
    }
    if (cachedAskId) {
      setAskId(cachedAskId); // Set the askId if it's cached
    }
  }, []);

  useEffect(() => {
    if (prefillQuestion) {
      setQuery(prefillQuestion); // Set the query state
      handleAskClick(prefillQuestion); // Pass the prefillQuestion to the function
    }
  }, [prefillQuestion]); // Depend on prefillQuestion

  useEffect(() => {
    const userId = Cookies.get('userId');
    if (userId) {
      fetchUniqId(userId).then(data => {
        if (data) {
          setDisplayName(data.displayName); // Assuming this data structure from your fetch
          setUniqId(data.uniqId); // Adjust based on actual data structure
        }
      });
    }
  }, [userId]); // Depend on userId to re-fetch if it changes

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!userId) {
        console.log("Waiting for user ID...");
        return; // Exit if userId is not available
      }
  
      setIsLoading(true);
      try {
        const userRes = await fetch(`/api/user/${userId}`);
        if (userRes.ok) {
          const userData = await userRes.json();
          setProfilePic(userData.profilePic || null);
        } else {
          setProfilePic(null); // Fallback if fetch fails
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setProfilePic(null); // Fallback in case of an error
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchUserProfile();
  }, [userId]); // Add userId as a dependency

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

    const handleAskClick = async (arg) => {
      // Prevent default behavior if arg is an event
  if (arg && arg.preventDefault) {
    arg.preventDefault();
  }
  // Determine whether arg is a string (question) or an event
  const question = typeof arg === 'string' ? arg : query;
    localStorage.removeItem('cachedResponse'); // Clear cache on new question
    localStorage.removeItem('cachedAskId'); // Clear cache on new question
    setIsLoading(true);
    setAskId(null);
    setIsStreaming(true);
    setIsAskingQuestion(true);
    setError(null);
    setResponse("");

    fetchController.current.abort();
    fetchController.current = new AbortController();
    const { signal } = fetchController.current;

    try {
      if (!userId) {
        throw new Error("User ID not found.");
      }
      console.log("Question:", question);
      const res = await fetch(`/api/ask`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, query: question }), 
        signal,
      });

      if (!res.ok || !res.body) {
        const errorText = await res.text(); // Attempt to read error response text
        console.error("API Error:", res.status, res.statusText, errorText);
        throw new Error("Network response was not ok");
      }

      setIsLoading(false); // Request done, hide spinner

      let accum = "";
      const decoder = new TextDecoder();
      let fullResponse = "";

      res.body.pipeTo(
        new WritableStream({
          write(chunk) {
            const chunkContent = decoder.decode(chunk);
            fullResponse += chunkContent;
            console.log("Chunk Content:", chunkContent);
            setResponse(prevResponse => prevResponse + chunkContent);
          },
          close() {
            setIsLoading(false);
            setIsStreaming(false);
            console.log("Final Response:", fullResponse);
            localStorage.setItem('cachedResponse', fullResponse); // Cache the final response
            setStreamCompleted(true);
          },
          abort(err) {
            setIsLoading(false);
            setIsStreaming(false);
            console.error("Stream aborted:", err);
            setStreamCompleted(false);
          },
        })
      );
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        setError(error.message || "Failed to fetch response. Please try again.");
      }
    } finally {
      setIsAskingQuestion(false); // Stop loading after the question is processed
    }
  };

  // Use useEffect to watch for the completion of the streaming
useEffect(() => {
  if (streamCompleted) {
    // Fetch the askId now that streaming is complete
    (async () => {
      const askId = await fetchAskId(userId, query);
      if (askId) {
        setAskId(askId); // Update state with fetched askId
        localStorage.setItem('cachedAskId', askId)
        console.log("Retrieved askId:", askId);
      } else {
        console.error("Failed to fetch askId.");
      }
    })().catch(error => console.error("Error fetching askId:", error));
  }
}, [streamCompleted]); // This useEffect depends on streamCompleted
  
  const handleStopClick = () => {
    fetchController.current.abort(); // Abort the fetch operation
    setIsLoading(false); // Reset the loading state
    setIsStreaming(false); // Ensure streaming is stopped
    setIsAskingQuestion(false); // Stop loading when stopping the question process
  };
      
 //-------- Profile Pic Upload ---------------//

           const handleFileChange = async (event) => {
            const file = event.target.files[0];

            setIsUploadingImage(true); // Start loading for image upload
          
            if (!file) {
              //setImageUploadError("No file selected.");
              toast({
                title: "Error",
                description: "An error occurred while uploading. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom"
              });
              return;
            }
          
            let imageFile = file;
          
            // Check if the file is HEIC and convert it
            if (file.type === "image/heic") {
              try {
                const convertedBlob = await heic2any({
                  blob: file,
                  toType: "image/jpeg",
                  quality: 1.0 // Adjust quality as needed
                });
                imageFile = new File([convertedBlob], "converted-image.jpg", { type: "image/jpeg" });
              } catch (error) {
                console.error("Error converting HEIC to JPEG:", error);
                setImageUploadError("Error converting image format.");
                return;
              }
            }
          
            processImage(imageFile); // Call a function to resize, compress, and upload the image
          };

          const processImage = (imageFile) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new window.Image();
                img.onload = () => {
                    // Calculate the center crop dimensions
                    const minDimension = Math.min(img.width, img.height);
                    const startX = (img.width - minDimension) / 2;
                    const startY = (img.height - minDimension) / 2;
        
                    // Create a canvas to draw the square cropped image
                    const squareCanvas = document.createElement('canvas');
                    squareCanvas.width = minDimension;
                    squareCanvas.height = minDimension;
                    const squareCtx = squareCanvas.getContext('2d');
                    squareCtx.drawImage(img, startX, startY, minDimension, minDimension, 0, 0, minDimension, minDimension);
        
                    // Create another canvas for the circular crop
                    const circularCanvas = document.createElement('canvas');
                    circularCanvas.width = 200; // Target size
                    circularCanvas.height = 200;
                    const circularCtx = circularCanvas.getContext('2d');
        
                    // Draw a circle and clip the context
                    circularCtx.beginPath();
                    circularCtx.arc(100, 100, 100, 0, 2 * Math.PI, false);
                    circularCtx.clip();
        
                    // Draw the square image onto the circular canvas
                    circularCtx.drawImage(squareCanvas, 0, 0, 200, 200);
        
                    // Convert circular canvas to blob and compress it
                    circularCanvas.toBlob((blob) => {
                        new Compressor(blob, {
                            quality: 0.6, // Adjust compression quality
                            success: (compressedBlob) => {
                                uploadImage(compressedBlob); // Upload the compressed blob
                            },
                            error: (err) => {
                                console.error("Compression error:", err);
                                //setImageUploadError("An error occurred during image compression.");
                                toast({
                                  title: "Error",
                                  description: "An error occurred while uploading. Please try again.",
                                  status: "error",
                                  duration: 5000,
                                  isClosable: true,
                                  position: "bottom"
                                });
                            },
                        });
                    }, 'image/jpeg', 0.75);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(imageFile);
        };
        
 
        const uploadImage = async (blob) => {
          setIsLoading(true);
          setImageUploadError(null);
        
          const formData = new FormData();
          formData.append('profilePic', blob, 'profilePic.jpg');
        
          try {
            const user = auth.currentUser;
            const idToken = user ? await user.getIdToken() : null;
            if (!idToken) {
              throw new Error("User is not authenticated");
            }
        
            const response = await fetch(`/api/uploadProfilePic`, {
              method: 'POST',
              headers: { 'Authorization': `Bearer ${idToken}` },
              body: formData,
            });
        
            if (!response.ok) {
              const errorText = await response.text();
              try {
                const errorResponse = JSON.parse(errorText);
                //setImageUploadError(`Error: ${errorResponse.message}`);
                toast({
                  title: "Error",
                  description: `Error: ${errorResponse.message}`,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "bottom"
                });
              } catch (jsonError) {
                //setImageUploadError(`Error: ${errorText}`);
                toast({
                  title: "Error",
                  description: `Error: ${errorText}`,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "bottom"
                });
              }
              return;
            }
        
            const data = await response.json();
            setProfilePic(data.imageUrl);
          } catch (error) {
            console.error("Error uploading image:", error);
            //setImageUploadError("An error occurred while uploading. Please try again.");
            toast({
              title: "Error",
              description: "An error occurred while uploading. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "bottom"
            });
          } finally {
            setIsUploadingImage(false); // Stop loading after upload is done or fails
          }
        };

        const fetchUniqId = async (userId) => {
          try {
            const response = await fetch(`/api/getDisplayName/${userId}`);
            if (response.ok) {
              const data = await response.json();
              return { uniqId: data.uniqId, displayName: data.displayName };
            } else {
              throw new Error('Failed to fetch uniqId');
            }
          } catch (error) {
            console.error('Error fetching uniqId:', error);
            return { uniqId: null, displayName: null }; 
          }
        };

        const handleShareAndCopy = async () => {
          if (!userId) {
            console.error('No userId found');
            // Handle the case of missing userId
            return;
          }
        
          const uniqId = await fetchUniqId(userId);
          if (!uniqId) {
            console.error('No uniqId found');
            // Handle the case of missing uniqId
            return;
          }
        
          const userDotLink = `/${uniqId}`;
        
          // Logic for sharing and copying the link
          try {
            if (isLargerThanMD) {
              // For medium or larger screens, copy to clipboard
              await navigator.clipboard.writeText(userDotLink);
              toast({
                title: "Link Copied",
                description: "The link has been copied to your clipboard.",
                status: "success",
                duration: 600,
                isClosable: true,
                render: () => (
                  <Box color="white" p={3} bg="#1c1c1c" >
                    <Text fontWeight="bold">Message Deleted</Text>
                    <Text mt={1}>The message has been successfully deleted.</Text>
                  </Box>
                ),
            });
            } else {
              // For smaller screens, use the native share dialog
              if (navigator.share) {
                await navigator.share({
                  title: 'Check out my Dot',
                  url: userDotLink,
                });
                // Display success toast after successful sharing
                toast({
                  title: "Link Copied",
                  description: "The link has been copied to your clipboard.",
                  status: "success",
                  duration: 600,
                  isClosable: true,
                  render: () => (
                    <Box color="white" p={3} bg="#1c1c1c" >
                      <Text fontWeight="bold">Message Deleted</Text>
                      <Text mt={1}>The message has been successfully deleted.</Text>
                    </Box>
                  ),
              });
                console.log('Content shared successfully');
              }
            }
          } catch (error) {
            console.error('Error:', error);
            // Removed the error toast as per your request
          }
        };

        const copyShareLink = async (askId) => {
          const shareLink = `https://dotspot.ai/share/${askId}`;
          try {
            if (isLargerThanMD) {
              // For medium or larger screens, copy to clipboard
              await navigator.clipboard.writeText(shareLink);
              toast({
                title: "Link Copied",
                description: "The link has been copied to your clipboard.",
                status: "success",
                duration: 2000,
                isClosable: true,
                render: () => (
                  <Box color="white" p={3} bg="#1c1c1c" >
                    <Text fontWeight="bold">Link Copied</Text>
                    <Text mt={1}>The link has been copied to your clipboard.</Text>
                  </Box>
                ),
            });
            } else {
              // For smaller screens, use the native share dialog
              if (navigator.share) {
                await navigator.share({
                  title: 'Check out my Dot',
                  url: shareLink,
                });
                // Notify the user that the content was shared successfully
                toast({
                  title: "Link Copied",
                  description: "The link has been copied to your clipboard.",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                  render: () => (
                    <Box color="white" p={3} bg="#1c1c1c" >
                      <Text fontWeight="bold">Link Copied</Text>
                      <Text mt={1}>The link has been copied to your clipboard.</Text>
                    </Box>
                  ),
              });
              }
            }
          } catch (error) {
            console.error('Error:', error);
          }
        };

        // Assuming this function is defined within the component or imported
          const fetchAskId = async (userId, query) => {
            // Using only one userId since askerUserId and receiverUserId are the same
            if (!userId) {
                console.error("Missing userId");
                return;
            }
          
            const queryParams = new URLSearchParams({
                askerUserId: userId,
                receiverUserId: userId,
                query
            }).toString();
          
            try {
                const response = await fetch(`/api/getAskId?${queryParams}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch askId");
                }
              
                const { askId } = await response.json();
                  console.log("Retrieved askId:", askId);
                  localStorage.setItem('cachedAskId', askId); // Save askId to localStorage
                  return askId; // Use askId as needed
            } catch (error) {
                console.error("Error fetching askId:", error);
                return null; // Return null on error
            }
          };
          
  return (
    <div>
    <Flex direction="column" align="center" mx="auto" maxWidth="2xl" width="100%" p={4}>
      <Box position="relative" mb={2} width="100%" textAlign="center">
        <Box position="relative" display="inline-block">
          {profilePic ? (
            <Image
              src={profilePic}
              borderRadius="full"
              boxSize="70px"
              alt="User Profile"
              mx="auto"
            />
          ) : (
            // Render a transparent placeholder or nothing
            <Avatar
              name={displayName} // Pass the full displayName for the Avatar component to extract initials
              size="lg" // Adjust the size as needed
              bg="#C6C6C6" // Background color of the Avatar
              color="gray.800" // Text color for the initials
            />
          )}
        <button
          aria-label="Upload profile picture"
          style={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            borderRadius: '50%', 
            border: 'none', 
            backgroundColor: '#FF0050',
            color: 'white', // Set text color to white for the plus sign
            fontSize: '20px', 
            //fontWeight: 'bold', 
            cursor: 'pointer',
            width: '20px', // Width of the circle
            height: '20px', // Height of the circle
            display: 'flex', // Use flex to center the plus sign inside the button
            alignItems: 'center', // Align vertically
            justifyContent: 'center', // Align horizontally
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)' // Optional shadow for depth
          }}
          onClick={() => fileInputRef.current.click()} // Trigger file upload dialog
        >
          +
        </button>
           {/* Hidden File Input */}
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
                {/* Display spinner when loading */}
                {isUploadingImage && (
          <Spinner 
          color="blue.500" 
          position="absolute" 
          top="50%" 
          left="50%" 
          transform="translate(-50%, -50%)" />
        )}
      </Box>
    </Box>

    {/* Display error message */}
    {imageUploadError && ( 
      <Text color="red.500" textAlign="center" mt={2}>
        {imageUploadError}
      </Text>
    )}
      {/* Text Description */}
                <Text 
            color="white" 
            textAlign="center" 
            mb={4} 
            width="100%" 
            fontSize={{ base: "14px", md: "md", lg: "lg" }}
            lineHeight= "1.4"
          >
            Ask yourself anything, get recommendations,<br />
            or search your notes.
          </Text>
      <Flex mb={4} align="center" justify="center" width="100%">
      <InputGroup size="md" w="full" position="relative">
                <AutoResizeTextarea
                  placeholder="Ask a question or ask for recs"
                  fontSize={{ base: "16px", md: "md", lg: "lg" }}
                  value={query}
                  onChange={handleInputChange}
                  color="black"
                  backgroundColor="white"
                  border="none"
                  _placeholder={{ color: "gray.500" }}
                  paddingEnd="4.5rem" // Ensure enough padding for the button
                  minH={{ base: "50px", md: "52px", lg: "56px" }} // Minimum height
                  lineHeight="1.2" // Adjust line height for vertical centering
                  //paddingTop="20px" // Adjust top padding for vertical centering
                  //paddingBottom="20px" // Adjust bottom padding for vertical centering
                  pt="18px"
                  pb="18px" // Top padding to vertically center the placeholder text
                  borderRadius="30px"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {  // Checks if Enter key is pressed without the Shift key
                      e.preventDefault();  // Prevents the default action of inserting a newline
                      handleAskClick();  // Call the handleAskClick function
                    }
                  }}
                />
                <Button
                  size="sm"
                  //onClick={handleAskClick}
                  colorScheme="pink"
                  backgroundColor="#FF0050"
                  _hover={{ bg: "#FF3371" }}
                  onClick={isAskingQuestion ? handleStopClick : handleAskClick}
                  //isLoading={isLoading}
                  borderRadius="full"
                  position="absolute"
                  right="10px" // Align to the right
                  bottom="12px" // Align to the bottom, adjusted to center
                  height="30px" // Fixed height
                  width="44px" // Fixed width
                  zIndex="2" // Assign a higher z-index
                >
                  {isAskingQuestion ? (
                  <Image src="/assets/ask_stop_icon.svg" alt="Stop" boxSize="16px"/>
                  ) : (
                    <Image src="/assets/ask_send_icon.svg" alt="Send" boxSize="22px"/>
                  )}
                </Button>
              </InputGroup>
              </Flex>
                    {response || isStreaming ? (
                      <Flex direction="column" align="center" mx="auto" maxWidth="3xl" p={0} fontSize={{ base: "16px", md: "md", lg: "xl" }}>
                        <Box mt={2} width="100%" textAlign="left" bgColor="transparent" className="markdown-content">
                          {isStreaming && !response ? (
                            <Spinner 
                            thickness="4px"
                            speed="0.75s"
                            emptyColor="#1c1c1c"
                            color="#FF0050"
                            size="md" /> // Loading spinner when waiting for the response
                          ) : (
                            <Markdown color="white">{response + (isStreaming ? " ⚪" : "")}</Markdown> // Show response with streaming indicator
                          )}
                        </Box>
                          
                      </Flex>
            ) : null}
    </Flex>
    </div>
  );
};

export default AskComponent;
