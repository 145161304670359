import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import PhoneLayout from './LandingPagePhoneLayout';
import ComputerLayout from './LandingPageComputerLayout';

const LandingPage = () => {
  // This will return 'phone' for smaller screens and 'computer' for larger screens
  const layout = useBreakpointValue({ base: 'phone', lg: 'computer' }, {ssr: false});

  return (
    <>
      {layout === 'phone' && <PhoneLayout />}
      {layout === 'computer' && <ComputerLayout />}
    </>
  );
};

export default LandingPage;
