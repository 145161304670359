// App.tsx

import React from "react";
import "./App.css";
import AppContent from "./components/AppContent";
import LandingPage from "./components/LandingPage";
import AuthStateHandler from "./auth/AuthStateHandler";
//import Header from "./Header"; // Import the Header component
import { ChakraProvider, Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AskLink from "./components/AskLink";
import Welcome from "./components/Welcome";
import OnBoardingQuestions from "./components/OnBoardingQuestions";
import { AuthProvider } from './auth/AuthContext';
import usePlausible from "./utils/usePlausible";
import Tidbits from "./components/Tidbits";
import AskFeedbackPage from "./components/AskFeedbackPage";
import AskShareLink from "./components/AskShareLink";

function App() {
  usePlausible();
  
  return (
        <Router>
    <AuthProvider>
      <ChakraProvider>
          <AuthStateHandler />
          <Box
            className="App__Container"
            minHeight="100vh"
            backgroundColor="#1C1C1C"
          >
            <Routes>
              <Route path="/" element={<AppContent />} />
              <Route path="/signup" element={<LandingPage />} />
              <Route path="/completeDisplayName" element={<Welcome />} />
              <Route path="/completeOnboardingQuestions" element={<OnBoardingQuestions />} />
              <Route path="/:uniqId" element={<AskLink />} />
              <Route path="/tidbits" element={<Tidbits />} />
              <Route path="/ask/:askId" element={<AskFeedbackPage />} />
              <Route path="/share/:askId" element={<AskShareLink />} />
              {/* ... other routes */}
            </Routes>
          </Box>
      </ChakraProvider>
    </AuthProvider>
        </Router>
  );
}

export default App;
