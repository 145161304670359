import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import {
  Heading,
  Text,
  VStack,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Flex,
  Box,
  Spinner,
  Image,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { sendSignInLinkToEmail } from "firebase/auth";
import landingPagePhoneSvg from "../assets/landing_page_phone.svg";
import { auth } from "../auth/firebaseConfig";
import notesSvg from "../assets/notes_computer_new.svg";
import askSvg from "../assets/ask_computer_new.svg";
import bioLinkSvg from "../assets/biolink_computer_new.svg";
import missionSvg from "../assets/mission_computer.svg";
//import notesSvg from "../assets/notes.svg";
//import askSvg from "../assets/ask.svg";
//import bioLinkSvg from "../assets/biolink.svg";

const ComputerLayout = () => {
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSignIn = async () => {
    if (!email) {
      toast({
        title: "Please enter an email address.",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    setIsLoading(true); // Start loading

    try {
      // Simulate a delay, then send sign-in link
      setTimeout(async () => {
        await sendSignInLinkToEmail(auth, email, {
          url: `${window.location.origin}/`,
          handleCodeInApp: true,
        });
        // Set email in a cookie
        Cookies.set('emailForSignIn', email, { expires: 1 }); // 1 day expiration
        setIsLoading(false); // Stop loading
        onOpen(); // Open the modal
      }, 0);
    } catch (error) {
      setIsLoading(false); // Stop loading in case of error
      // Handle errors
    }
  };


  return (
    <>
      {/* Header */}
      <Flex direction="row" align="center" justify="space-between" py={6} px={{ md: 20, lg: 40 }}>
        <Heading as="h1" color="white" fontWeight="bold" textAlign="left" letterSpacing="-8px" fontFamily="Poppins, sans-serif" fontSize={{ md: "6xl", lg: "7xl" }}>
          dots
        </Heading>
      </Flex>

      {/* Main Content */}
      <Flex direction="row" align="center" justify="space-between" py={0} px={{ md: 20, lg: 40 }} mb={0}>
        <Box flex={1}>
          <VStack align="start" spacing={4}>
            <Text color="white" fontSize={{ md: "48px", lg: "55px" }} fontWeight="bold" textAlign="left" lineHeight="shorter" mt={{ md: 0, lg: -20 }} mb={20}>
              Get to know yourself.
            </Text>
            <Input placeholder="Enter your email to log in or sign up" value={email} onChange={(e) => setEmail(e.target.value)} bg="white" color="black" py={7} pl={6} width={{ base: "80", md: "500px" }} size="lg" height="78px" fontSize="20px" borderRadius="40px" />
            <Button bg="#FF0050" color="white" onClick={handleSignIn} py={7} width={{ base: "80%", md: "500px" }} size="lg" height="78px" fontSize="20px" _hover={{ bg: "#F7004E" }} borderRadius="40px" isLoading={isLoading} spinner={<Spinner thickness="4px" speed="0.65s" size="md" />} mb={{ md: "20", lg: "40" }}>
              Continue
            </Button>
            {/* Success Modal */}
            <Modal isOpen={isOpen} isCentered onClose={onClose} closeOnOverlayClick={true} closeOnEsc={true}>
              <ModalOverlay bg="blackAlpha.900" />
              <ModalContent bgColor="#1c1c1c" opacity="1" py="30px" color="white" borderRadius="2xl">
                <ModalHeader color="white" textAlign="center">Verification Link Sent!</ModalHeader>
                <ModalBody>
                  <Flex direction="column" align="center" justify="center" w="full">
                    <img src="/assets/email_link_sent.svg" alt="Email Sent" width="50px" height="50px" />
                    <Text textAlign="center" mt={6} mb={2}>A sign-in link was sent to {email}. Click it to sign-in to Dots.</Text>
                  </Flex>
                </ModalBody>
              </ModalContent>
            </Modal>
          </VStack>
        </Box>
        <Box minW="550" maxW={{ lg: "1000" }} ml={{ md: 0, lg: 24 }} display={{ base: "none", md: "flex" }} justifyContent="center" mb={20} mt={-20}>
          <Image src={landingPagePhoneSvg} alt="Landing Page Visual" width="60%" height="60%" display={{ base: "none", md: "block" }} />
        </Box>
      </Flex>

      {/* New Sections Below */}
<Flex direction="column" px={{ md: 20, lg: 20 }}>
  <Divider borderColor="#343434" mb={200} borderWidth="1px" my="150px"/>

  {/* Section 1 to 4 */}
  {[
    { svg: notesSvg, isCentered: false },
    { svg: askSvg, isCentered: false },
    { svg: bioLinkSvg, isCentered: false },
    //{ svg: missionSvg, isCentered: true }, // Assuming there's an SVG for the mission statement
  ].map((section, index) => (
    <>
      <Box w="full" pt={5} textAlign={section.isCentered ? "center" : "left"} bg={section.isCentered ? "white" : "transparent"} pl={{ base: "0", md: "20", lg: "100px" }}>
        <Flex justifyContent={section.isCentered ? "center" : "flex-start"}>
          <Image 
            src={section.svg} 
            alt="Illustration" 
            mt={3} 
            //boxSize={{ md: "400px", lg: "400px" }} // Adjust the size for responsiveness
            objectFit="contain" // This ensures the SVG scales properly within the given dimensions
          />
        </Flex>
      </Box>
      <Divider borderColor="#343434" borderWidth="1px" my="150px"/>
      {/* Apply bottom padding to the Box wrapping each section for spacing, not directly to the Divider */}
    </>
  ))}

        {/* Section 4 text mission SVG */}
        <Box w="full" pt={0} pb={100}>
      <Flex justifyContent="center">
        <Image src={missionSvg} alt="Share link illustration" mt={8} />
        </Flex>
        </Box>
        
        <Box mt="30px" mb="30px" height="1px" bgColor="#343434" width="full"></Box>

       {/* Footer */}
    <Box w="full" pt={10} pb={5} px={{ md: 20, lg: 20 }}>
    <VStack align="start" spacing={2}>
    <Text color="white" fontSize="3xl" fontWeight="bold" letterSpacing="-4px" mb={2}>dots</Text>
    <Text color="white">About</Text>
    <Text color="white">Team</Text>
    <Text color="white">Careers</Text>
    <Text color="white">Terms and Policies</Text>
    <Text color="white">Privacy</Text>
    <Text color="white" fontWeight="bold" mt={5}>Dots © 2024</Text>
  </VStack>
</Box>
      </Flex>
    </>
  );
};

export default ComputerLayout;