import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import {
  Heading,
  Text,
  VStack,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Flex,
  Box,
  Spinner,
  Divider,
  Link,
  Image,
} from "@chakra-ui/react";
import { sendSignInLinkToEmail } from "firebase/auth";
import { auth } from "../auth/firebaseConfig";
import notesPNG from "../assets/notes.png";
import askPNG from "../assets/ask_new.png";
import bioLinkPNG from "../assets/biolink_new.png";
import missionSvg from "../assets/mission_computer.svg";

const PhoneLayout = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const handleSignIn = async () => {
    if (!email) {
      toast({
        title: "Please enter an email address.",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    setIsLoading(true); // Start loading

    try {
      // Simulate a delay, then send sign-in link
      setTimeout(async () => {
        await sendSignInLinkToEmail(auth, email, {
          url: `${window.location.origin}/`,
          handleCodeInApp: true,
        });
        // Set email in a cookie
        Cookies.set('emailForSignIn', email, { expires: 1 }); // 1 day expiration
        setIsLoading(false); // Stop loading
        onOpen(); // Open the modal
      }, 0);
    } catch (error) {
      setIsLoading(false); // Stop loading in case of error
      // Handle errors
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <VStack
      spacing={4}
      align="start"
      mt={0}
      w="full"
    >
      <Heading
        px={{ base: "4", md: "8" }}
        as="h1"
        color="white"
        letterSpacing="-7px"
        fontWeight="bold"
        textAlign="left"
        fontFamily="Poppins, sans-serif"
        fontSize="4.0rem"
        mb={5}
        mt={10}
      >
        dots
      </Heading>

      <Text
        px={{ base: "4", md: "8" }}
        color="white"
        fontSize="55px"
        fontWeight="bold"
        textAlign="left"
        lineHeight="1.0"
        mt={0}
        mb={20}
      >
        Get to know yourself.
      </Text>

     {/* Outer Box to control padding around the entire section */}
<Box px={{ base: "4", md: "8" }} w="full">
  {/* Inner VStack for vertical stacking without affecting element widths */}
  <VStack spacing={4} w="full">
    <Input
      placeholder="Enter your email to log in or sign up"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      bg="white"
      color="black"
      py={7}
      width="full" // Maintains full width inside VStack
      size="lg"
      height="65px"
      fontSize="18px"
      borderRadius="30px"
    />

    <Button
      onClick={handleSignIn}
      bg="#FF0050"
      color="white"
      borderRadius="30px"
      py={7}
      width="full" // Maintains full width inside VStack
      size="lg"
      height="65px"
      fontSize="18px"
      _hover={{ bg: "#F7004E" }}
      isLoading={isLoading}
      spinner={<Spinner thickness="4px" speed="0.65s" size="md" />}
      mb={150}
    >
      Continue
    </Button>
  </VStack>
          </Box>

          <Box mt="30px" mb="30px" height="10px" bgColor="black" width="full"></Box>
           {/* Section 1 */}
          <Box w="full" pt={0} px={{ base: "4", md: "8" }}>
            <Text 
              textAlign="left"
              fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }} 
              lineHeight="1.0" 
              fontWeight="bold" 
              mb={0} // No bottom margin for the first line
              color="white"
            >
              Add notes about you.
            </Text>
        <Text textAlign="left" color="white" lineHeight="1.2" mt={2}>Tell your Dot what you like. The more you share, the better it knows you.</Text>
        <Flex justifyContent="center">
        <Image src={notesPNG} alt="Add notes illustration" mt={8} />
        </Flex>
      </Box>

      <Box mt="30px" mb="30px" height="10px" bgColor="black" width="full"></Box>

      {/* Section 2 */}
      <Box w="full" pt={0} px={{ base: "4", md: "8" }}>
      <Text textAlign="left" fontSize={{base: "3xl", md: "3xl", lg: "3xl"}} lineHeight="1.0" fontWeight="bold" mb={2} color="white">
      Get personalized answers.
    </Text>
        <Text textAlign="left" color="white" lineHeight="1.2" mt={2}>Your Dot works for you! Get answers, content, and recs.</Text>
        <Flex justifyContent="center">
        <Image src={askPNG} alt="Ask questions illustration" mt={8} />
        </Flex>
      </Box>

      <Box mt="30px" mb="30px" height="10px" bgColor="black" width="full"></Box>


      {/* Section 3 */}
      <Box w="full" pt={0} px={{ base: "4", md: "8" }}>
      <Text textAlign="left" fontSize={{base: "3xl", md: "3xl", lg: "3xl"}} lineHeight="1.0" fontWeight="bold" mb={2} color="white">
      Share Your link.
    </Text>
        <Text textAlign="left" color="white" lineHeight="1.2" mt={2}>Let anyone ask you questions and get to know you instantly.</Text>
        <Flex justifyContent="center">
        <Image src={bioLinkPNG} alt="Share link illustration" mt={8} />
        </Flex>
      </Box>

     {/* Section 4 text mission SVG */}
      {/* <Box w="full" pt={20} pb={10}>
      <Flex justifyContent="center">
        <Image src={missionSvg} alt="Share link illustration" mt={8} />
        </Flex>
        </Box> */}

      <Box mt="30px" mb="30px" height="1px" bgColor="black" width="full"></Box>

         {/* Section 4 text mission SVG */}
         <Box w="full" px={{ base: "4", md: "8" }} >
      <Flex justifyContent="center">
        <Image src={missionSvg} alt="Share link illustration" mt={8} />
        </Flex>
        </Box>
      
        <Box mt="30px" mb="30px" height="1px" bgColor="black" width="full"></Box>

      
      {/* <Flex mt={20} px={0} w="full"> */}
        {/* <Box w="full" pt={10} pb={2} bg="white" textAlign="center" m={0}> */}
          {/* <Text color="black">Our mission is to be the fastest path to information.</Text> */}
          {/* <Text color="black" fontSize="2xl" fontWeight="bold" letterSpacing="-4  px" mb={5}>dots</Text> */}
        {/* </Box> */}
      {/* </Flex> */}
      

      {/* Section 5 - Footer */}
             {/* Footer */}
    <Box w="full" pt={10} pb={5} px={{ base: "4", md: "8" }}>
    <VStack align="start" spacing={2}>
    <Text color="white" fontSize="3xl" fontWeight="bold" letterSpacing="-4px" mb={3}>dots</Text>
    <Text color="white" fontSize="xs">About</Text>
    <Text color="white" fontSize="xs">Team</Text>
    <Text color="white" fontSize="xs">Careers</Text>
    <Text color="white" fontSize="xs">Terms and Policies</Text>
    <Text color="white" fontSize="xs">Privacy</Text>
    <Text color="white" fontSize="xs" fontWeight="bold" mt={3}>Dots © 2024</Text>
  </VStack>
</Box>

      {/* Back to Top Link */}
      <Flex justify="center" w="full" pt={5} pb={12}>
        <Link color="white" onClick={scrollToTop} textDecoration="underline">Back to top ↑</Link>
      </Flex>
                {/* Success Modal */}
                <Modal isOpen={isOpen} isCentered onClose={onClose} closeOnOverlayClick={true} closeOnEsc={true}>
              <ModalOverlay
                        bg="blackAlpha.900" // Adjust the background color and opacity
                      />
                   <ModalOverlay />
  <ModalContent bgColor="#1c1c1c" opacity="1" py="20px" color="white" borderRadius="2xl" >
  <ModalHeader color="white" textAlign="center">Verification Link Sent!</ModalHeader>
    <ModalBody>
      <Flex direction="column" align="center" justify="center" w="full">
        <img src="/assets/email_link_sent.svg" alt="Email Sent" width="50px" height="50px" />
        <Text textAlign="center" mt={6} mb={2}>A sign-in link was sent to {email}. Click it to sign-in to Dots. </Text>
      </Flex>
    </ModalBody>
  </ModalContent>
</Modal>
    </VStack>
  );
};

export default PhoneLayout;
