import React, { useState, useEffect } from "react";
import withFirebaseAuth from "../utils/with_firebase_auth";
import { useBreakpointValue, Image, Avatar, Input, useMediaQuery, useToast } from "@chakra-ui/react";
import Cookies from 'js-cookie';
import Markdown from "react-markdown";
import { useAuth } from '../auth/AuthContext'; // Adjust the path as needed
import Masonry from 'react-masonry-css';
import ShareIcon2 from "../assets/share_window_arrow.svg"


import {
  IconButton,
  Box,
  Grid,
  GridItem,
  Text,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useClipboard,
  useToast,
  Button,
  Flex,
  Progress,
  useMediaQuery,
} from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
// Import Chakra UI components
import { CopyIcon } from "@chakra-ui/icons";

// Assuming you have separate files for each type of card data
import permanentCardData from "/Users/drew/Desktop/react/react-feed/mock_server/mock_data/permanentCard.cjs"; // Adjust the path as necessary
import systemCardsData from "/Users/drew/Desktop/react/react-feed/mock_server/mock_data/systemCards.cjs";
import messageCardsData from "/Users/drew/Desktop/react/react-feed/mock_server/mock_data/messageCards.cjs";

const USER_ID = localStorage.getItem('userId'); // Retrieve userId from local storage

const Inbox = ({ notes, onNavigate, onUpdateNotification }) => {
  const { authState } = useAuth();
  // const userId = authState.userId; // Get userId from authentication state
  const userId = Cookies.get('userId'); // Get userId from authentication state
  const [selectedCard, setSelectedCard] = useState(null); // State to keep track of the selected card
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [clickedCards, setClickedCards] = useState({}); // State to keep track of clicked cards
  const [feedback, setFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [cardFeedback, setCardFeedback] = useState({});
  const [localNotes, setLocalNotes] = useState([]);
  const [messageCards, setMessageCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const maxWValue = useBreakpointValue({ base: "100%", md: "lg" });
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const [isDeleting, setIsDeleting] = useState(false); // Tracks the deletion process
  const breakpointColumnsObj = {
    default: 2, // when the width is greater than 1200px, show 2 columns
    768: 2, // when the width is less than 768px, show 2 columns for phone layout
    // You can add more breakpoints here if you need
  };
  const [displayName, setDisplayName] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [askerInfo, setAskerInfo] = useState({ displayName: '', profilePic: '' });
  const [searchTerm, setSearchTerm] = useState("");

  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`/api/getDisplayName/${userId}`);
        if (response.ok) {
          const data = await response.json();
          setDisplayName(data.displayName);
          setProfilePic(data.profilePic);
        } else {
          console.error('Failed to fetch user info');
          // Handle the error appropriately
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        // Handle the error appropriately
      }
    };

    if (userId) {
      fetchUserInfo();
    }
  }, [userId]);

  useEffect(() => {
    const fetchQuestionsAndAskerInfo = async () => {
      try {
        // Fetch function wrapped with Firebase authentication
        const fetchWithAuth = withFirebaseAuth(fetch);

        const response = await fetchWithAuth(`/api/questions`);
        if (!response.ok) throw new Error('Failed to fetch questions');
        const questions = await response.json();

        // Assuming selectedCard.card_id is the ID of the question you're interested in
        const selectedQuestion = questions.find(question => question.askId.toString() === selectedCard.card_id);
        if (selectedQuestion) {
          setAskerInfo({
            displayName: selectedQuestion.Asker.displayName,
            profilePic: selectedQuestion.Asker.profilePic,
          });
        } else {
          console.error('Selected question not found');
          // Handle case where the selected question isn't in the fetched list
        }
      } catch (error) {
        console.error(error.message);
        // Handle error appropriately
      }
    };

    if (selectedCard && selectedCard.card_id) {
      fetchQuestionsAndAskerInfo();
    }
}, [selectedCard]);
  //const permanentCards = [
  //  {
  //    card_id: "permanent1",
  //    card_type: "permanent",
  //    content_title: "Get more questions by sharing your dot.",
  //    content_response: null,
  //  },
  //];

  //const systemCards = [
  //  {
  //    card_id: "system1",
  //    card_type: "system",
  //    content_title: "Ask your Dot a question.",
  //    content_response:
  //      "See how your Dot responds to questions.\n\nAdd feedback to tailor the response!",
  //    actionType: "addNote", // for example, 'askQuestion', 'addNote', etc.
  //  },
  //  {
  //    card_id: "system2",
  //    card_type: "system",
  //    content_title: "Get amazing recs!",
  //    content_response:
  //      "Discover new places based on your tastes.\n\nFind new things to watch and read.",
  //    actionType: "getRec",
  //  },
  //  {
  //    card_id: "system3",
  //    card_type: "system",
  //    content_title: "Share more information about you",
  //    content_response:
  //      "Never repeat yourself. The more you share, the faster people can know you.\n\nLet notes do the work for you.\n\nMore notes =  better recs.",
  //    actionType: "getRec",
  //  },
  //];

  const allCardsData = [ ...messageCards];
  const sortedCardsData = [ ...messageCards];

  const combinedCards = [ ...messageCards];

  const { onCopy } = useClipboard("dot.me/drew");
  const toast = useToast();

  // Retrieve clickedCards from localStorage when the component mounts
  useEffect(() => {
    const savedClickedCards = localStorage.getItem("clickedCards");
    if (savedClickedCards) {
      setClickedCards(JSON.parse(savedClickedCards));
    }
  }, []);

  // Save clickedCards to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("clickedCards", JSON.stringify(clickedCards));

    // Check if there are any unclicked system cards
    const hasUnclickedCards = sortedCardsData.some(
      (card) =>
        (card.card_type === "system" || card.card_type === "message") &&
        !clickedCards[card.card_id]
    );
    onUpdateNotification(hasUnclickedCards); // Update parent state
  }, [clickedCards]);

  console.log("All Cards Data:", allCardsData);
  // Function to truncate the content

  // Function to handle navigation to the 'Ask' tab
  const navigateToAskQuestion = () => {
    onNavigate(0); // 0 for 'Ask' tab
  };

  // Function to handle navigation to the 'Ask' tab
  const navigateToGetRec = () => {
    onNavigate(0); // 0 for 'Ask' tab
  };

  // Function to handle navigation to the 'Notes' tab
  const navigateToNotes = () => {
    onNavigate(1); // Adjust the index if necessary
  };

  // Fetch message cards from the database
  useEffect(() => {
    const fetchQuestions = async () => {
      setIsLoading(true);
      try {
        // Ensure we have a valid userId before fetching
        if (!userId) {
          console.error("No userId found");
          setIsLoading(false);
          return;
        }
        // Fetch function wrapped with Firebase authentication
        const fetchWithAuth = withFirebaseAuth(fetch);

        const response = await fetchWithAuth(`/api/questions`);
        if (response.ok) {
          const questions = await response.json();
          setMessageCards(
            questions.map((question) => ({
              card_id: question.askId.toString(),
              card_type: "message",
              content_title: question.askText,
              content_response: question.answerText,
              asker_user_id: question.askerUserId,
              asker_displayName: question.askerDisplayName,
              asker_profilePic: question.askerProfilePic,
              seenStatus: question.seenStatus,
            }))
          );
        } else {
          console.error("Failed to fetch questions");
          console.log("askerprofilepic", asker_profilePic);
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setIsLoading(false); }
    };

    if (userId) {
      fetchQuestions();
    }
  }, [userId]);

  const getTruncatedContent = (contentResponse) => {
    if (!contentResponse) {
      return "No additional details available"; // A default message for empty content
    }
  
    const maxLength = 200;
    let truncated = "";
    const lines = contentResponse.split("\n");
  
    for (let line of lines) {
      if (truncated.length + line.length > maxLength) {
        truncated += line.substring(0, maxLength - truncated.length) + "...";
        break;
      } else {
        truncated += line + "\n";
      }
    }
  
    return truncated; // Return the truncated string
  };
  

  const handleCopyButtonClick = (e) => {
    e.stopPropagation(); // Prevent the card click event
    onCopy(); // Trigger clipboard copy
    // Directly show the toast without waiting for hasCopied to update
    toast({
      title: "Link copied!",
      status: "success",
      duration: 3500,
      isClosable: true,
      render: () => (
        <Box color="white" p={3} bg="#FF0050">
          <Text fontWeight="bold">Link copied!</Text>
          <Text>Share your link with the world.</Text>
        </Box>
      ),
    });
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    setIsSubmitting(true);
  
    // Use userId from authentication state
    if (!userId) {
      console.error("User is not authenticated");
      setIsSubmitting(false); // Stop the submission process
      // Show a toast or some other UI element to inform the user
      toast({
        title: "Error",
        description: "You must be logged in to submit feedback.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom"
      });
      return;
    }

    // Concatenate the original question and feedback
    const fullFeedbackContent = `Question: ${selectedCard.content_title}  My Feedback: ${feedback}`;
    const askerQuestion = `${selectedCard.content_title}`;
    const askeeFeedback = `${feedback}`

    // Construct the new note object for feedback
    const feedbackNote = {
      userId: userId,
      relatedAskId: selectedCard.card_id,
      content: fullFeedbackContent, // Updated content with question and feedback
      embedding: null,
      embeddingModel: null,
      tokenCount: null,
      tokenCost: 0,
      status: "ENABLED",
      isArchived: false,
      createdAt: new Date().toISOString(),
      noteType: "FEEDBACK",
      updatedAt: new Date().toISOString(),
    };

    try {
      // Fetch function wrapped with Firebase authentication
      const fetchWithAuth = withFirebaseAuth(fetch);
  
      // Submit feedback to your API
      const feedbackResponse = await fetchWithAuth(`/api/feedback/${selectedCard.card_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ feedbackText: fullFeedbackContent, askersQuestion: askerQuestion, askeesFeedback: askeeFeedback }),
      });
  
      if (!feedbackResponse.ok) {
        throw new Error("Failed to submit feedback");
      }
  
      // Submit the note to your API
      const noteResponse = await fetchWithAuth(`/api/notes`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(feedbackNote),
      });
  
      if (!noteResponse.ok) {
        throw new Error("Failed to create note");
      }
  
      const responseData = await noteResponse.json();
      const addedNote = { ...feedbackNote, noteId: responseData.noteId };
      setLocalNotes((prevNotes) => [...prevNotes, addedNote]);
      setCardFeedback((prev) => ({ ...prev, [selectedCard.card_id]: true }));
  
      // Close the modal and show success message
      setIsModalOpen(false);
      toast({
        title: "Feedback Submitted",
        description: "Your feedback has been added as a note.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom"
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Error",
        description: "There was an issue submitting your feedback.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom"
      });
    } finally {
      setFeedback(""); // Reset feedback field
      setIsSubmitting(false); // Stop the loading state
    }
  };

  const fetchFeedbackDetails = async (askId) => {
    try {
      const response = await fetch(`/api/feedback/${askId}`);
      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data); // Log the response data
        setCardFeedback((prevState) => ({
          ...prevState,
          [askId]: data.feedbackSubmitted,
        }));
        setFeedback(data.feedbackText || "");
      } else {
        console.error("Failed to fetch feedback details");
      }
    } catch (error) {
      console.error("Error fetching feedback details:", error);
    }
  };

  const handleCardClick = async (card) => {
    if (card.card_type === "message") {
      setSelectedCard(card);
      setIsModalOpen(true);
      fetchFeedbackDetails(card.card_id);

      // Update seen status if the card was not already seen
      if (!card.seenStatus) {
        await updateMessageSeenStatus(card.card_id);
        setMessageCards((prevCards) =>
          prevCards.map((c) =>
            c.card_id === card.card_id ? { ...c, seenStatus: true } : c
          )
        );
      }
    } else if (card.card_type === "system") {
      setClickedCards((prevState) => ({
        ...prevState,
        [card.card_id]: true,
      }));

      // Navigate based on actionType
      switch (card.actionType) {
        case "askQuestion":
          navigateToAskQuestion();
          break;
        case "addNote":
          navigateToNotes();
          break;
        case "getRec":
          navigateToGetRec();
          break;
        default:
        // Handle any default case or do nothing
      }
    } else if (card.card_type !== "permanent") {
      setSelectedCard(card);
      setIsModalOpen(true);
    }
  };

  // Update Message Seen Status in the Database
  const updateMessageSeenStatus = async (cardId) => {
    const receiverUserId = Cookies.get("userId"); // Assuming you store receiverUserId in localStorage
    if (!receiverUserId) {
      console.error("Receiver User ID not found");
      return;
    }
    const seenAt = new Date().toISOString(); // Current timestamp
    try {
      const response = await fetch(`/api/messages/seen`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          receiverUserId,
          askId: cardId,
          seenAt,
          seenStatus: true,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to update message seen status");
      }
    } catch (error) {
      console.error("Error updating message seen status:", error);
    }
  };

  const fetchUniqId = async (userId) => {
    try {
      const response = await fetch(`/api/getDisplayName/${userId}`);
      if (response.ok) {
        const data = await response.json();
        return data.uniqId;
      } else {
        throw new Error('Failed to fetch uniqId');
      }
    } catch (error) {
      console.error('Error fetching uniqId:', error);
      return null;
    }
  };

  const fetchAskerInfo = async (userId) => {
    try {
        const response = await fetch(`/api/getDisplayName/${userId}`); // Assuming this endpoint exists
        if (response.ok) {
            const data = await response.json();
            // Assuming the response includes askerProfilePic and asker_displayName
            return { profilePic: data.askerProfilePic, displayName: data.asker_displayName };
        } else {
            throw new Error('Failed to fetch asker info');
        }
    } catch (error) {
        console.error('Error fetching asker info:', error);
        return null;
    }
};

            const handleShareAndCopy = async () => {
              const userId = Cookies.get('userId'); // Fetch the userId from cookies
              if (!userId) {
                console.error('No userId found');
                // Handle the case of missing userId
                return;
              }
            
              const uniqId = await fetchUniqId(userId);
              if (!uniqId) {
                console.error('No uniqId found');
                // Handle the case of missing uniqId
                return;
              }

              const askerInfo = await fetchAskerInfo(userId);
              if (!askerInfo) {
                console.error('No asker info found');
                // Handle the case of missing asker info
                return;
              }
            
              const userDotLink = `/${uniqId}`;

              const askerDetails = await fetchUniqId(userId);
              if (!uniqId) {
                console.error('No uniqId found');
                // Handle the case of missing uniqId
                return;
              }
            
              // Logic for sharing and copying the link
          try {
          if (isLargerThanMD) {
            // For medium or larger screens, copy to clipboard
            await navigator.clipboard.writeText(userDotLink);
            toast({
              title: "Link Copied",
              description: "The link has been copied to your clipboard.",
              status: "success",
              duration: 600,
              isClosable: true,
              position: "bottom"
            });
          } else {
            // For smaller screens, use the native share dialog
            if (navigator.share) {
              await navigator.share({
                title: 'Check out my Dot',
                url: userDotLink,
              });
              console.log('Content shared successfully');
            }
          }
          } catch (error) {
          console.error('Error:', error);
          toast({
            title: "Error",
            description: "There was an error sharing the link.",
            status: "error",
            duration: 1200,
            isClosable: true,
            position: "bottom"
          });
          }
          };

          const handleDeleteMessage = async (cardId) => {
            console.log("Disabling card with ID:", cardId);
            setIsDeleting(true); // Start the disabling process
          
            try {
              const fetchWithAuth = withFirebaseAuth(fetch);
              // Adjust the endpoint to match your API for disabling a card
              const response = await fetchWithAuth(
                `/api/disableCard`, // Use the disableCard endpoint
                {
                  method: "POST", // Use POST as specified in your disableCard API endpoint
          
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ askId: cardId, receiverUserId: userId }), // Send the cardId as askId in the body
                }
              );
          
              if (response.ok) {
                // Option 1: Filter out the disabled card from your local state
                setMessageCards(currentCards => currentCards.filter(card => card.card_id !== cardId));
                // Option 2: If you have a separate state for whether a modal is open, make sure to close it
                setIsModalOpen(false);
                // If you're tracking deletion status, you can now indicate the process has finished
                setIsDeleting(false);
    
                // Show a toast to inform the user of the successful deletion
                toast({
                    title: "Message Deleted",
                    description: "The message has been successfully deleted.",
                    status: "success",
                    duration: 600,
                    isClosable: true,
                    render: () => (
                      <Box color="white" p={3} bg="#1c1c1c" >
                        <Text fontWeight="bold">Message Deleted</Text>
                        <Text mt={1}>The message has been successfully deleted.</Text>
                      </Box>
                    ),
                });
            } else {
                // Handle the case where the server response is not OK (e.g., deletion failed)
                console.error("Server responded with non-OK status");
                setIsDeleting(false); // Indicate the deletion process has stopped due to an error
            }
        } catch (error) {
            console.error("Error disabling card:", error);
            setIsDeleting(false); // In case of an error, stop the deletion process
        }
    };

    const filteredMessageCards = sortedCardsData.filter((card) =>
  card.content_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
  card.content_response.toLowerCase().includes(searchTerm.toLowerCase()) ||
  card.asker_displayName.toLowerCase().includes(searchTerm.toLowerCase())
);
          
        const copyShareUrl = async (url) => {
          try {
            if (isLargerThanMD) {
              // For medium or larger screens, copy to clipboard
              await navigator.clipboard.writeText(url);
              toast({
                title: "Link Copied",
                description: "The link has been copied to your clipboard.",
                status: "success",
                duration: 600,
                isClosable: true,
                render: () => (
                  <Box color="white" p={3} bg="#1c1c1c" >
                    <Text fontWeight="bold">Link Copied</Text>
                    <Text mt={1}>The link has been copied to your clipboard.</Text>
                  </Box>
                ),
            });
            } else {
              // For smaller screens, use the native share dialog
              if (navigator.share) {
                await navigator.share({
                  title: 'Check out my Dot',
                  url: url,
                });
                // Notify the user that the content was shared successfully
                toast({
                  title: "Link Copied",
                  description: "The link has been copied to your clipboard.",
                  status: "success",
                  duration: 600,
                  isClosable: true,
                  render: () => (
                    <Box color="white" p={3} bg="#1c1c1c" >
                      <Text fontWeight="bold">Link Copied</Text>
                      <Text mt={1}>The link has been copied to your clipboard.</Text>
                    </Box>
                  ),
              });
              }
            }
          } catch (error) {
            console.error('Error:', error);
          }
        };
        
  return (
    <div>
      {/* Flex container for search bar and sort button */}
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        padding="0px"
        marginBottom="20px"
      >
        {/* Container for search bar and sort button */}
        <Flex
          width="100%" // Full width by default
          maxW="md" // Maximum width on larger screens
          mx="auto" // Centers the container
          alignItems="center"
          justifyContent="space-between"
        >
          {/* Search bar */}
          <Input
            type="text"
            placeholder="Search your inbox"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            flex={1} // Takes up available space
            marginLeft="10px"
            marginRight="10px"
            backgroundColor="#303133"
            //backgroundColor="#1c1c1c"
            color="white"
            fontSize="1rem"
            outline="none"
            borderRadius="full"
            //border="1px solid white" // Set the border width, style, and color
            border="none"
            marginBottom="20px"
          />
          </Flex>
      {isLargerThanMD ? (
        // Layout for larger screens
        <Box
          padding={0}
          maxWidth="4xl" // Adjust maximum width as needed
          mx="auto" // Centers the container
          className="notes-columns"
          pb="100px"
        >
          <Masonry
            breakpointCols={breakpointColumnsObj} // Always 2 columns
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
      {filteredMessageCards
        .filter(
          (card) => !(card.card_type === "system" && clickedCards[card.card_id])
        )
        .map((card) => (
          <Box
            key={card.card_id}
            position="relative"
            borderWidth="1px"
            borderRadius="10px"
            overflow="hidden"
            onClick={() => handleCardClick(card)}
            cursor="pointer"
            className="note-card"
            borderColor="white"
          >
            {/* Notification Circle */}
            {card.card_type === "message" && !card.seenStatus && (
              <Box
                position="absolute"
                top="10px"
                right="10px"
                height="10px"
                width="10px"
                backgroundColor="#FF0050"
                borderRadius="50%"
              />
            )}

            {/* Title and Content Response */}
            {/* Card content using Flexbox */}
            <Flex direction="column" padding={2}>
              {/* Title */}
              <Text
                fontSize={{base: "sm", md: "md", lg: "lg" }}
                fontWeight="600"
                mb="4px"
                paddingLeft="4px"
                paddingTop="4px"
                paddingRight="20px"
              >
                {card.content_title}
              </Text>
            </Flex>

            {/* Logic specific for 'message' card type */}
            {card.card_type === "message" && (
                <Box borderWidth="2px" borderColor="white" borderTop="none">
                  <Box backgroundColor="white" textAlign="left" p={1} display="flex" alignItems="center">
                    {card.asker_profilePic ? (
                      <Image
                        borderRadius="full"
                        boxSize={{base: "24px", md: "30px"}} // Adjust the size as needed
                        src={card.asker_profilePic}
                        alt="Asker's Profile"
                        mr={2}
                      />
                    ) : (
                      // Using the `Box` component to explicitly set the size of the `Avatar`
                      <Box width={{base: "24px", md: "30px"}} height={{base: "24px", md: "30px"}} mr={2} display="inline-flex" alignItems="center" justifyContent="center">
                      <Avatar
                        name={card.asker_displayName} // Use displayName to generate initials
                        borderRadius="full"
                        bg="#D9D9D9" // Grey background
                        color="#000000" // Dark grey letters
                        // Setting the size of the Avatar to fill the Box
                        size="full"
                      />
                    </Box>
                    )}
                    <Text fontSize={{base: "sm", md: "md", lg: "md"}} fontWeight="600" color="black">
                      {card.asker_displayName}
                    </Text>
                  </Box>
                </Box>
              )}

        {/* Logic for 'system' card type goes here */}

          </Box>  
        ))}
            </Masonry>
            </Box>
    ) : (
           // Layout for smaller screens
           <Box padding={0} className="notes-columns" mx="auto" pb="100px">
            <Masonry
            breakpointCols={2} // Always 2 columns
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
          {filteredMessageCards
        .filter(
          (card) => !(card.card_type === "system" && clickedCards[card.card_id])
        )
            .map((card) => (
              <Box
                key={card.card_id}
                position="relative"
                borderWidth="1px"
                borderRadius="10px"
                overflow="hidden"
                onClick={() => handleCardClick(card)}
                cursor="pointer"
                className="note-card"
                borderColor="white"
              >
                {/* Notification Circle */}
                {card.card_type === "message" && !card.seenStatus && (
                  <Box
                    position="absolute"
                    top="10px"
                    right="10px"
                    height="10px"
                    width="10px"
                    backgroundColor="#FF0050"
                    borderRadius="50%"
                  />
                )}
    
                {/* Title and Content Response */}
                {/* Card content using Flexbox */}
                <Flex direction="column" padding={2}>
                  {/* Title */}
                  <Text
                    fontSize="sm"
                    fontWeight="800"
                    mb="4px"
                    paddingLeft="4px"
                    paddingTop="4px"
                    paddingRight="20px"
                  >
                    {card.content_title}
                  </Text>
                </Flex>
    
                {/* Logic specific for 'message' card type */}
                {card.card_type === "message" && (
                  <Box borderWidth="2px" borderColor="white" borderTop="none">
                    <Box backgroundColor="white" textAlign="left" p={1} display="flex" alignItems="center">
                      {card.asker_profilePic ? (
                        <Image
                          borderRadius="full"
                          boxSize={{base: "24px", md: "30px"}} // Adjust the size as needed
                          src={card.asker_profilePic}
                          alt="Asker's Profile"
                          mr={2}
                        />
                      ) : (
                        // Using the `Box` component to explicitly set the size of the `Avatar`
                          <Box width={{base: "24px", md: "30px"}} height={{base: "24px", md: "30px"}} mr={2} display="inline-flex" alignItems="center" justifyContent="center">
                          <Avatar
                            name={card.asker_displayName} // Use displayName to generate initials
                            borderRadius="full"
                            bg="#D9D9D9" // Grey background
                            color="#000000" // Dark grey letters
                            // Setting the size of the Avatar to fill the Box
                            size="full"
                          />
                        </Box>
                      )}
                      <Text fontSize={{base: "sm", md: "md", lg: "md"}} fontWeight="600" color="black">
                        {card.asker_displayName}
                      </Text>
                    </Box>
                  </Box>
                )}
    
                {/* Logic for 'system' card type goes here */}

              </Box>
            ))}
        </Masonry>
        </Box>
        )}
        </Flex>
{isModalOpen && selectedCard && (
  <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="full">
    <ModalOverlay />
    <ModalContent m={0} bg="#1C1C1C" color="white" pt={10} pb={4} pl={4} pr={4}>
      {isSubmitting && (
        <Progress
          size="xs"
          isIndeterminate
          colorScheme="red"
          sx={{
            "&": {
              backgroundColor: "#1C1C1C",
            },
            "& > div": {
              backgroundColor: "#FF0050",
            },
          }}
        />
      )}
      <ModalCloseButton />

        {/* Header */}
                <Box textAlign="center" mb={4}>
            <Text fontSize="3xl" fontWeight="bold" letterSpacing="-3px">
              dots
            </Text>
          </Box>

          <Box maxW={{ base: "100%", md: "70%", lg: "50%" }} mx="auto" p={5}>
          {/* External Ask Tag */}
          <Box
            bg="white"
            borderRadius="full"
            px={3}
            py={1}
            display="inline-block"
            mb={5}
            minWidth="auto"
          >
            <Text color="#FF0050" fontWeight="bold" fontSize={{ base: "sm", md: "md", lg: "lg" }}>External Ask</Text>
          </Box>

          {/* Content Title */}
          <Text fontSize={{ base: "xl", md: "xl", lg: "3xl" }} lineHeight="1.2" fontWeight="bold" mb={6} color="white">
            {selectedCard.content_title}
          </Text>

          {/* Content Response */}
          <Text fontSize={{ base: "lg", md: "xl", lg: "xl" }} color="#FF0050" mb={2}>
            Your dot answered:
          </Text>
          <Box className="markdown-content" fontSize={{ base: "md", md: "lg", lg: "xl" }} lineHeight="tall" mb={8} color="white">
            <Markdown>
              {selectedCard.content_response}
            </Markdown>
          </Box>

    {/* Feedback Box */}
    <Box
      borderWidth="1px"
      borderRadius="10px"
      overflow="hidden"
      borderColor="white"
      mt={4}
      mb={100}
    >
      <Box backgroundColor="white" textAlign="left" paddingLeft="7.5px">
        <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight="600" color="black" p={1}>
          Not happy with the response? Add feedback:
        </Text>
      </Box>
      <Box padding={3}>
        <Textarea
          placeholder="Type your feedback here..."
          value={feedback}
          onChange={handleFeedbackChange}
          maxLength={500}
          border="none"
          resize="none"
          height="100px"
          fontSize={{ base: "md", md: "md", lg: "lg" }}
        />
        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Text mr={2}>{feedback.length} / 500</Text>
          <Button
            onClick={handleSubmitFeedback}
            isLoading={isSubmitting}
            colorScheme="red"
            bg="#FF0050"
            color="white"
            fontWeight="bold"
          >
            Submit
          </Button>
        </Flex>
      </Box>
    </Box>
  </Box>
              {cardFeedback[selectedCard.card_id] && (
                <Flex justifyContent="center" mt={8}>
                  <Box
                    backgroundColor="white"
                    borderRadius="full" // sm for smaller rounded corners
                    p={2} // padding around the text
                    width="full" // full width to match the parent container
                    maxW="container.md" // adjust the maximum width to match the boxes above
                  >
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      color="black"
                      textAlign="center"
                    >
                      Thanks! We have updated your
                      <Text as="span" color="#FF0050">
                        {" "}
                        Dot.
                      </Text>
                    </Text>
                  </Box>
                </Flex>
              )}
            {/* Floating Action Buttons Container */}
                  <Box 
                    position="fixed" 
                    bottom="0" 
                    left="0" 
                    right="0" 
                    p={4} 
                    textAlign="center"
                    zIndex="modal" // Ensure this zIndex places the buttons above all other content
                    bg="#1c1c1c"
                  >
                    <Flex justifyContent="center" alignItems="center" gap="4">
                      {/* Delete Message Button */}
                      <Button
                        width={{ base: "auto", sm: "auto" }} // Adjust width as needed
                        px={{ base: "6", md: "8" }} // Padding to ensure adequate space around the text
                        onClick={() => handleDeleteMessage(selectedCard.card_id)}
                        backgroundColor="#FF0050"
                        color="white"
                        height={{ base: "56px", md: "58px", lg: "60px" }}
                        borderRadius="30px"
                        _hover={{ bg: "#ef004b" }}
                        fontSize={{ base: "md", md: "md", lg: "xl" }}
                      >
                        Delete Message
                      </Button>
                                
                      {/* Share Button */}
                      <Button
                        width={{ base: "auto", sm: "auto" }} // Adjust width as needed
                        px={{ base: "6", md: "8" }} // Padding to ensure adequate space around the text
                        onClick={() => copyShareUrl(`https://dotspot.ai/share/${selectedCard.card_id}`)}
                        backgroundColor="#333333" // Black background for the Share button
                        color="white"
                        height={{ base: "56px", md: "58px", lg: "60px" }}
                        borderRadius="30px"
                        _hover={{ bg: "#333333" }} // Darker on hover
                        fontSize={{ base: "md", md: "md", lg: "xl" }}
                      >
                         Share
                              <img src={ShareIcon2} alt="Share" style={{ width: '16px', height: '16px', marginLeft: '8px' }} />
                      </Button>
                    </Flex>
                  </Box>
      
            </ModalContent>
          </Modal>
      )}
            <Box position="fixed" bottom="0" left="0" right="0" bg="#1c1c1c" p={4} textAlign="center">
        <Button
         width={{ base: "90%", md: "70%", lg: "30%" }}
         mx="auto"
         onClick={handleShareAndCopy}
         backgroundColor={{ base: "#FF0050", md: "#FF0050", lg: "#FF0050"}}
         color={{ base: "#white", md: "white", lg: "white" }}
         height={{ base: "56px", md: "58px", lg: "60px" }}
         borderRadius={{ base: "30px", md: "30px" }}
         _hover={{bg: { md: "#ef004b" }}}
         fontSize={{base: "sm", md:"md", lg:"lg"}}
        >
          Share your Dot to get questions
        </Button>
      </Box>
      </div>
  );
};

export default Inbox;