import Markdown from "react-markdown";
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Box, Text, Button, useToast, Flex, Image, Modal, Spinner,
  ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, useDisclosure, Spacer
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import withFirebaseAuth from "../utils/with_firebase_auth";
import homeIcon from "../assets/home_icon.svg";

const AskSharePage = () => {
  const { askId } = useParams();
  const [questionAndResponse, setQuestionAndResponse] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAskDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/questions/${askId}`);
        if (response.ok) {
          const details = await response.json();
          setQuestionAndResponse(details); // Assuming the API returns the expected structure
        } else {
          toast({
            title: "Error",
            description: "Failed to fetch ask details.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error fetching ask details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAskDetails();
  }, [askId, toast]);

  if (!questionAndResponse) return <Box>Loading...</Box>;

  const Header = () => {
    const userId = Cookies.get('userId');
    const isLoggedIn = userId !== undefined;

    return (
      <Flex alignItems="center" p={4} bg="#1c1c1c" borderBottom="1px solid" borderColor="#4A4C50">
      <Spacer /> {/* Left Spacer for centering "dots" */}
      <Text fontSize="2xl" fontWeight="bold" letterSpacing="-3px" color="white" textAlign="center" flex="1">
        dots
      </Text>
      <Spacer /> {/* Right Spacer for centering "dots" */}
      <Button as={Link} to={isLoggedIn ? "/" : "/signup"} colorScheme="pink" backgroundColor="#FF0050" color="white" borderRadius="full" _hover={{ bg: '#E6004C', color: 'white' }} position="absolute" right="4">
        {isLoggedIn ? "Home" : "Sign Up"}
      </Button>
    </Flex>
    );
  };

  return (
    <>
      <Header />
      <Flex direction="column" align="center" mx="auto" maxWidth="2xl" p={4} color="white" bg="#1C1C1C">
      <Box px={{ base: 4, md: 8 }} width="100%">
            
            <Text fontSize="xl" fontWeight="bold" color="white" textAlign="left" mb={4}>
              {questionAndResponse.askText}
            </Text>

            <Box mt={2} width="100%" textAlign="left" className="markdown-content">
              {isLoading ? (
                <Spinner color="#FF0050" />
              ) : (
                <Markdown>{questionAndResponse.answerText}</Markdown>
              )}
            </Box>
          </Box>
      </Flex>
    </>
  );
};

export default AskSharePage;