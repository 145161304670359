import React, { useState, useEffect, useRef } from 'react';
import {
  VStack, Box, Button, Text, Spinner, Flex, Tag, TagLabel, TagCloseButton,
  Input, InputGroup, InputRightElement, IconButton, useOutsideClick, Link as ChakraLink
} from '@chakra-ui/react';
import { SearchIcon, Search2Icon, InfoIcon, ChevronRightIcon, SettingsIcon, ArrowRightIcon } from '@chakra-ui/icons';
import Markdown from 'react-markdown';
import { AddIcon } from '@chakra-ui/icons';
//import { ReactComponent as MoreInfoIcon } from '/src/assets/button_more_info.svg';
//import { ReactComponent as SimilarTidbitIcon } from '/src/assets/button_skip_next.svg';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';



const TidbitComponent: React.FC = () => {
  const [response, setResponse] = useState<string>("");
  //const [interests, setInterests] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const fetchController = useRef<AbortController>(new AbortController());
  const [showInterests, setShowInterests] = useState<boolean>(false); // State to control visibility
  const [interests, setInterests] = useState([{ interest: 'exampleInterest', tagId: 'exampleTagId' }]);
  const userId = Cookies.get('userId')
  const navigate = useNavigate();
  const [userSearchInput, setUserSearchInput] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const ref = React.useRef();



  useEffect(() => {
    const fetchInterests = async () => {
      try {
        const userId = Cookies.get('userId');
        console.log('Fetching interests for user:', userId);
        if (userId) {
          const response = await fetch(`/api/user/${userId}/interests`);
          console.log('fetchInterests response:', response);
          const data = await response.json();
          console.log('Interests data:', data);
          setInterests(data.map(interest => ({ 
            interest: interest.interest, 
            tagId: interest.tagId,
            isLive: interest.isLive 
          })));
        }
      } catch (error) {
        console.error('Error fetching interests:', error);
        console.log('Detailed error info:', error);
        setError('Error fetching interests');
      }
    };

    fetchInterests();
    const cachedResponse = localStorage.getItem('cachedResponse');
    if (cachedResponse) {
      setResponse(cachedResponse);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue.length >= 2 && inputValue.length <= 40) {
      addInterest(inputValue);
      setInputValue("");
    }
  };

  const addInterest = async (interest) => {
    try {
      const userId = Cookies.get('userId');
      console.log('User ID:', userId);
      if (userId) {
        const response = await fetch(`/api/user/${userId}/interests`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ interest }),
            });
            console.log('addInterest response:', response);
            const newInterest = await response.json();
            console.log('New interest:', newInterest);
            setInterests(prevInterests => [...prevInterests, newInterest]);
            }
            } catch (error) {
            console.error('Error adding interest:', error);
            setError('Error adding interest');
            }
            };

            const removeInterest = async (tagId) => {
              console.log("Removing interest with tagId:", tagId);
              try {
                setInterests(interests.filter(i => i.tagId !== tagId));
            
                await fetch(`/api/interests/${tagId}`, {
                  method: 'PUT',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ status: false }),
                });
                console.log('removeInterest response:', response);
              } catch (error) {
                console.error('Error removing interest:', error);
                setError('Error updating interest');
              }
            };
  

  const handleGetTidbitClick = async () => {
    localStorage.removeItem('cachedResponse');
    setIsLoading(true);
    setIsStreaming(true);
    setError(null);
    setResponse("");

    fetchController.current.abort();
    fetchController.current = new AbortController();
    const { signal } = fetchController.current;

    try {
      const userId = Cookies.get('userId') || "db8b91c5-f6ca-45ba-9328-5c793e536ccd";

      const res = await fetch(`/api/getTidbit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId }),
        signal,
      });
      console.log('Tidbit response:', res);

      if (!res.ok || !res.body) {
        throw new Error("Network response was not ok");
      }

      let accum = "";
      const decoder = new TextDecoder();
      let fullResponse = "";

      res.body.pipeTo(
        new WritableStream({
          write(chunk) {
            const chunkContent = decoder.decode(chunk);
            fullResponse += chunkContent;
            setResponse(prevResponse => prevResponse + chunkContent);
          },
          close() {
            setIsLoading(false);
            setIsStreaming(false);
            localStorage.setItem('cachedResponse', fullResponse);
          },
          abort(err) {
            setIsLoading(false);
            setIsStreaming(false);
            console.error("Stream aborted:", err);
          },
        })
      );
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        setError(error.message || "Failed to fetch response. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopClick = () => {
    fetchController.current.abort();
  };

  const toggleInterestsDisplay = () => {
    if (inputValue === '') {
      setShowInterests(!showInterests);
    } else if (inputValue.length >= 2 && inputValue.length <= 40) {
      addInterest(inputValue);
      setInputValue('');
    }
  };

  const getSimilarTidbit = async (tidbit) => {
    //localStorage.removeItem('cachedResponse'); // Adjust if you want to use a different caching strategy
    setIsLoading(true);
    setIsStreaming(true);
    setError(null);
    setResponse("");

    fetchController.current.abort(); // Abort any ongoing fetch
    fetchController.current = new AbortController();
    const { signal } = fetchController.current;

    try {
      const res = await fetch(`/api/getSimilarTidbit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tidbitResponse: tidbit }),
        signal,
      });
      console.log('Similar Tidbit response:', res);

      if (!res.ok || !res.body) {
        throw new Error("Network response was not ok");
      }

      let fullResponse = "";

      const reader = res.body.getReader();
      const decoder = new TextDecoder();

      // Read the stream
      reader.read().then(function processText({ done, value }) {
        if (done) {
          console.log("Stream complete");
          setIsLoading(false);
          setIsStreaming(false);
          localStorage.setItem('cachedResponse', fullResponse); // Adjust if you want to use a different caching strategy
          return;
        }

        const chunkText = decoder.decode(value, {stream: true});
        fullResponse += chunkText;
        setResponse(prevResponse => prevResponse + chunkText);

        // Read the next chunk
        reader.read().then(processText);
      });

    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        setError(error.message || "Failed to fetch similar tidbit. Please try again.");
      }
    } finally {
      if (!isStreaming) { // Ensure isLoading is only set to false when not streaming
        setIsLoading(false);
      }
    }
  };

  const queryMoreTidbitInfo = async (tidbit) => {
    localStorage.removeItem('cachedResponse'); // Adjust based on your caching strategy
    setIsLoading(true);
    setIsStreaming(true);
    setError(null);
    setResponse("");
  
    fetchController.current.abort(); // Abort any ongoing fetch
    fetchController.current = new AbortController();
    const { signal } = fetchController.current;
  
    try {
      const res = await fetch(`/api/queryTidbitInfo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tidbitResponse: tidbit }),
        signal,
      });
      console.log('Query Tidbit Info response:', res);
  
      if (!res.ok || !res.body) {
        throw new Error("Network response was not ok");
      }
  
      let fullResponse = "";
      const reader = res.body.getReader();
      const decoder = new TextDecoder();
  
      reader.read().then(function processText({ done, value }) {
        if (done) {
          console.log("Stream complete");
          setIsLoading(false);
          setIsStreaming(false);
          localStorage.setItem('cachedResponse', fullResponse);
          return;
        }
  
        const chunkText = decoder.decode(value, {stream: true});
        fullResponse += chunkText;
        setResponse(prevResponse => prevResponse + chunkText);
  
        reader.read().then(processText);
      });
  
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        setError(error.message || "Failed to fetch more tidbit info. Please try again.");
      }
    } finally {
      if (!isStreaming) {
        setIsLoading(false);
      }
    }
  };

  const toggleIsLive = async (tagId, newIsLiveStatus) => {
    // Update locally first
    setInterests((currentInterests) =>
      currentInterests.map((interest) =>
        interest.tagId === tagId ? { ...interest, isLive: newIsLiveStatus } : interest
      )
    );
  
    // Construct the request for the backend update
    try {
      const response = await fetch(`/api/interests/${tagId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isLive: newIsLiveStatus }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update the isLive status on the server.');
      }
  
      // Optionally, refresh or update UI based on the success
      console.log('Successfully updated isLive status');
    } catch (error) {
      console.error(error.message);
      // Handle failure by potentially reverting the change locally
    }
  };


  return (
      <Flex direction="column" align="center" mx="auto" maxWidth="xl" p={4}>
        {/* Header */}
        <Flex direction="column" alignItems="center" p={4} bg="#1c1c1c">
          <Text fontSize="3xl" fontWeight="bold" letterSpacing="-5px" color="white">
            dots
          </Text>
        </Flex>
       {/* Toggle Button and Input Field */}
       <Flex justify="flex-end" align="center" p={4} position="relative">
          <InputGroup size="md" display={showInterests ? 'inline-flex' : 'none'}>
            <Input
              pr="4.5rem"
              type="text"
              placeholder="Add an interest"
              borderRadius="full"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              color="white"
              bg="black"
              borderColor="#5C5C5C" // Set the border color
              //focusBorderColor="#5C5C5C" // Set the border color on focus
              //_hover={{ borderColor: "#5C5C5C" }} // Optional: Set the border color on hover
            />
            <InputRightElement width="2.9rem">
              <Button 
              h="1.75rem" 
              size="sm" 
              p={0}
              borderRadius="full"
              backgroundColor="#FF0050"
              color="white"
              onClick={() => {
                if (inputValue.length >= 2 && inputValue.length <= 40) {
                  addInterest(inputValue);
                  setInputValue("");
                }
              }}>
        <AddIcon />
      </Button>
    </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Display Tags */}
        {showInterests && (
            <Flex wrap="wrap" justify="center" p={4}>
                {interests.map(({ interest, tagId, isLive }) => (
                  <Tag
                    key={tagId}
                    m={1}
                    bgColor={isLive ? "#FF0050" : "gray.300"} // Blue for active, gray for inactive
                    cursor="pointer"
                    onClick={() => toggleIsLive(tagId, !isLive)} // Pass the tagId and new status
                  >
                    <TagLabel color={isLive ? "white" : "gray.500"}>{interest}</TagLabel>
                    <TagCloseButton onClick={(e) => {
                      e.stopPropagation(); // Prevent toggle click from firing when deleting
                      removeInterest(tagId);
                    }} />
                  </Tag>
                ))}
            </Flex>
        )}

      {/* Main Content */}
<VStack spacing={4} align="left" p={0} mx="auto" maxWidth="lg" pb="100px">
  {/* Streamed Response */}
  {response && (
    <>
      {/* Wrap the response and buttons in a Box with a black background */}
      <Box
        width="100%"
        bgColor="black" // Set the background color to black
        color="white"
        pb="0"
        textAlign="left"
        //border="1px solid" // Add a border
        //borderColor="#5C5C5C" // Set the border color
        className="response-container" // Add a class for potential additional styling
        borderRadius="10"
      >
        {/* Response text */}
        <Box pb="4" p="4" className="markdown-content">
          <Markdown>{response}</Markdown>
        </Box>

        {/* Buttons */}
        <Flex justifyContent="center" mb="4"> {/* Adjust the bottom margin as needed */}
          <Button
            leftIcon={<InfoIcon />} // Replace with the actual icon you're using
            colorScheme="#0050FF" // Adjust the color scheme as needed
            variant="solid"
            onClick={() => queryMoreTidbitInfo(response)} // Implement this function next
            mr={2} // Add some margin to the right of the first button
          >
            More Info
          </Button>
          <Button
            //leftIcon={<img src={SimilarTidbitIcon} alt="More info" />}
            leftIcon={<ChevronRightIcon />}
            colorScheme="#50FF00" // Adjust the color scheme as needed
            variant="solid"
            onClick={() => getSimilarTidbit(response)} // Pass the current tidbit as an argument
          >
            Get Similar
          </Button>
        </Flex>
      </Box>
    </>
  )}
  {error && (
    <Text mt={4} color="red.500">
      {error}
    </Text>
  )}
</VStack>


      {/* Footer with Next Tidbit Button in a Black Box */}
      <Box position="fixed" bottom="0" left="0" right="0" bg="#202321" p={4} textAlign="center">
            
          {/* Flex container for the "Get Tidbit" and search icon buttons */}
          <Flex justifyContent="center" alignItems="center">
                <IconButton
                icon={<SettingsIcon />}
                onClick={() => navigate('/customtidbits')}
                mr={4} // Margin right to space it out from the next button
                aria-label="Settings"
                height="60px"
                width="60px"
                colorScheme="black"
                variant="solid"
              />
            <Button
              width={{ base: "auto", md: "auto", lg: "auto" }} // Adjusted for consistency
              borderRadius="full"
              onClick={handleGetTidbitClick}
              colorScheme="pink"
              backgroundColor="#FF0050"
              _hover={{ bg: "#FF3371" }}
              isLoading={isLoading}
              isDisabled={isLoading || isStreaming}
              spinner={<Spinner />}
              height="60px"
              marginRight="4" // Add some margin to separate the buttons
            >
              {isLoading ? "Loading..." : "Get Tidbit"}
            </Button>
            <IconButton
              icon={<Search2Icon />}
              colorScheme="black"
              variant="solid"
              onClick={toggleInterestsDisplay}
              height="60px"
              width="60px"
            />
          </Flex>
        </Box>
      </Flex>
  );
};

export default TidbitComponent;