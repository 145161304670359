//Notes.tsx
import Cookies from 'js-cookie';
import React, { useEffect, useState } from "react";
import {
  TriangleUpIcon,
  TriangleDownIcon,
  CalendarIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Input,
  IconButton,
  SimpleGrid,
  Text,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Spinner,
  useToast,
  Progress,
  Flex,
  ButtonGroup,
  useMediaQuery,
} from "@chakra-ui/react";
import withFirebaseAuth from "../utils/with_firebase_auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebaseConfig";
import { useAuth } from '../auth/AuthContext'; // Adjust the path as needed
import Masonry from 'react-masonry-css';

const fetchWithAuth = withFirebaseAuth(fetch);

interface Note {
  noteId: UUID; // Creates new UUID for each note
  userId: UUID; // Optional since it might not be present in all instances
  relatedAskId: UUID; // Optional
  //relatedOnboardingQuestionId: number; // Optional
  content: string;
  embedding: number[]; // An array of floats, optional
  embeddingModel: string; // Optional
  tokenCount: number; // Optional
  tokenCost: number; // Optional
  status: "ENABLED" | "DISABLED" | "DRAFT"; // As per the Sequelize ENUM
  isArchived: boolean; // Optional
  createDate: DATE;
  noteType: "NOTE" | "FEEDBACK" | "ONBOARDING_QUESTION";
  createdAt: string; // Assuming you have a field for creation time, optional
  updatedAt: string; // Assuming you have a field for update time, optional
}

function Notes() {
  const { authState } = useAuth();
  const userId = Cookies.get('userId'); // Get userId from authentication state
  const [notes, setNotes] = useState<Note[]>([]);
  const navigate = useNavigate();
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddModalOpen,
    onOpen: onAddModalOpen,
    onClose: onAddModalClose,
  } = useDisclosure();
  const [newNoteContent, setNewNoteContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deletion progress
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState("");
  //const [sortOrder, setSortOrder] = useState<'descending' | 'descending'>('descending');
  const [sortOrder, setSortOrder] = useState<"ascending" | "descending">(
    "descending"
  );
  const breakpointColumnsObj = {
    default: 2, // Default, for large screens
    768: 2, // Two columns for screens smaller than 768px
  };
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const masonryBreakpointCols = isLargerThanMD ? breakpointColumnsObj : { default: 2 };
 // const [noteContent, setNoteContent] = useState(selectedNote.content);
 //const [noteContent, setNoteContent] = useState(selectedNote ? selectedNote.content : "");
 const [noteContent, setNoteContent] = useState('');

 useEffect(() => {
  if (selectedNote && selectedNote.content) {
    setNoteContent(selectedNote.content);
  }
}, [selectedNote]); // Dependency array includes selectedNote to react to changes


  useEffect(() => {
    console.log("useEffect is running");
    const fetchNotes = async () => {
      console.log("Fetching notes");

      if (!userId) {
        console.error("User ID not found. Please log in again.");
        // Optionally navigate to login or show error
        return;
      }

      try {
        console.log("Sending request to server");
        // Include the userId in the request
        const response = await fetchWithAuth(`/api/notes`);

        console.log("Received response from server");
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }

        console.log("Parsing response to JSON");
        const data = await response.json();

        console.log("Data received:", data);
        // Sort notes by createdAt date
        const sortedNotes = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNotes(sortedNotes); // Update the state with the sorted notes
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };

    fetchNotes();
  }, [userId, navigate]); // Add userId to the dependency array

  
  const getTruncatedContent = (content: string) => {
    return content.length > 200 ? `${content.substring(0, 200)}...` : content;
  };

  const handleCardClick = (note: Note) => {
    setSelectedNote(note);
    onOpen();
  };

  const handleCloseModal = () => {
    setNewNoteContent(""); // Reset the content
    onAddModalClose(); // Use the existing onAddModalClose function
  };

  const handleAddNote = async () => {
    setIsSaving(true);

    if (!userId) {
      console.error("User ID not found. Please log in again.");
      // Optionally navigate to login or show error
      setIsSaving(false);
      return;
    }

    // Prepare the new note object based on the Sequelize model
    const newNote = {
      userId: userId, // the user signed in and userId from localstorage,
      relatedAskId: null,
      //relatedOnboardingQuestionId: null,
      content: newNoteContent, // Ensure this variable contains the note content
      // embedding: embeddingData.embedding, handled in backend
      embeddingModel: null,
      tokenCount: null,
      tokenCost: null,
      status: "ENABLED",
      isArchived: false,
      createDate: new Date().toISOString(),
      noteType: "NOTE",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      const response = await fetchWithAuth(`/api/notes`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newNote),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Use the noteId from the response to update the local state
        const addedNote = { ...newNote, noteId: responseData.noteId };

        setTimeout(() => {
          // Add the new note at the start or end of the list based on sortOrder
          setNotes((prevNotes) => {
            if (sortOrder === "descending") {
              return [addedNote, ...prevNotes]; // New note at the start for ascending
            } else {
              return [...prevNotes, addedNote]; // New note at the end for descending
            }
          });

          setNewNoteContent("");
          setIsSaving(false);

          toast({
            title: "Note Added",
            description: "Your note has been successfully added.",
            status: "success",
            duration: 1500,
            isClosable: true,
            render: () => (
              <Box color="white" p={3} bg="#1C1C1C">
                <Text fontWeight="bold">Note Added</Text>
                <Text>Your note has been successfully added.</Text>
              </Box>
            ),
          });

          onAddModalClose();
        }, 500);
      } else {
        // Handle non-OK responses here
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      console.error("Error saving note:", error);
      setIsSaving(false);
    }
  };

  const handleUpdateNote = async () => {
    setIsSaving(true); // Show loading spinner in the button
  
    try {
      const response = await fetchWithAuth(`/api/notes/${selectedNote.noteId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ content: noteContent }), // Send updated note content
      });
  
      if (response.ok) {
        // Note updated successfully
        toast({
          title: "Note Updated",
          description: "Your note has been updated successfully.",
          status: "success",
          duration: 1500,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Updated</Text>
              <Text>Your note has been updated successfully.</Text>
            </Box>
          ),
        });
  
        // Update the selected note content in local state
        setSelectedNote((prevNote) => {
          return { ...prevNote, content: noteContent };
        });
  
        // Here we update the notes array to reflect this change
        setNotes(prevNotes => {
          return prevNotes.map(note => 
            note.noteId === selectedNote.noteId ? { ...note, content: noteContent } : note
          );
        });
  
        // Close the modal
        setIsSaving(false)
        onClose();
      } else {
        console.error("Failed to update note:", response.statusText);
        // Handle error scenario
      }
    } catch (error) {
      console.error("Error updating note:", error);
      // Handle error scenario
    } finally {
      setIsSaving(false); // Hide loading spinner
    }
  };
  
  const handleDeleteNote = async (noteId) => {
    console.log("Deleting note with ID:", noteId);
    setIsDeleting(true); // Start the deletion process

    try {
      console.log("Deleting note with ID:", noteId);
      const response = await fetchWithAuth(
        `/api/notes/${noteId}/disable`,
        {
          method: "PATCH", // Change to PATCH, which is more appropriate for updates

          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: "DISABLED" }), // Sending the new status
        }
      );

      if (response.ok) {
        // Update the state to reflect the change
        setNotes(
          notes.map((note) =>
            note.noteId === noteId ? { ...note, status: "DISABLED" } : note
          )
        );
        setIsDeleting(false); // Stop the deletion process
        onClose(); // Close the modal

        // Display the toast notification
        toast({
          title: "Note Deleted",
          description: "The note has been deleted.",
          status: "success",
          duration: 2000,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Deleted</Text>
              <Text>The note has been deleted.</Text>
            </Box>
          ),
        });
      } else {
        // Handle non-OK responses here
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      console.error("Error disabling note:", error);
      setIsDeleting(false); // In case of error, stop the deletion process
    }
  };

  const filteredNotes = notes.filter((note) =>
  note.content && note.content.toLowerCase().includes(searchTerm.toLowerCase())
);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => {
      const newOrder = prevOrder === "ascending" ? "descending" : "ascending";
      sortNotes(newOrder);
      return newOrder;
    });
  };

  const sortNotes = (order) => {
    const sortedNotes = [...notes].sort((a, b) => {
      if (order === "ascending") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });
    setNotes(sortedNotes);
  };

  return (
    <div>
      {/* Flex container for search bar and sort button */}
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        padding="0px"
        marginBottom="20px"
      >
        {/* Container for search bar and sort button */}
        <Flex
          width="100%" // Full width by default
          maxW="md" // Maximum width on larger screens
          mx="auto" // Centers the container
          alignItems="center"
          justifyContent="space-between"
        >
          {/* Search bar */}
          <Input
            type="text"
            placeholder="Search your notes"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            flex={1} // Takes up available space
            marginRight="10px"
            backgroundColor="#303133"
            //backgroundColor="#1c1c1c"
            color="white"
            fontSize="1rem"
            outline="none"
            borderRadius="full"
            //border="1px solid white" // Set the border width, style, and color
            border="none"

          />
          {/* Sort button */}
          <Button
            onClick={toggleSortOrder}
            backgroundColor="#303133"
            color="white"
            height="46px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{
              backgroundColor: "#303133", // Same as the default background color
              color: "white", // Same as the default text color
            }}
          >
            <CalendarIcon marginRight="5px" />
            {sortOrder === "ascending" ? "↑" : " ↓"}
          </Button>
        </Flex>
      </Flex>
{isLargerThanMD ? (
  // Layout for larger screens
              <Box
              padding={0}
              maxWidth="4xl" // Adjust maximum width as needed
              mx="auto" // Centers the container
              className="notes-columns"
              pb="100px"
            >
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {filteredNotes.map((note) =>
              note.status === "ENABLED" && note.userId === userId ? (
                <Box
                  key={note.note_id}
                  borderWidth="1px"
                  borderRadius="10px"
                  overflow="hidden"
                  onClick={() => handleCardClick(note)}
                  cursor="pointer"
                  className="note-card"
                  borderColor="white"
                >
                  <Box padding={3} textAlign="left">
            {note.noteType === "ONBOARDING_QUESTION" ? (
              // Specific formatting for ONBOARDING_QUESTION
              <>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} lineHeight="1.2" fontWeight="bold" mb={4} color="white">
                  {note.content.split("My Answer: ")[0].replace("Question: ", "")}
                </Text>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} color="white">
                  {note.content.split("My Answer: ")[1]}
                </Text>
              </>
            ) : note.content.includes("Question:") && note.content.includes("My Feedback:") ? (
              // Specific formatting for notes with "Question:" and "My feedback:"
              <>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} lineHeight="1.2" fontWeight="bold" mb={4} color="white">
                  {note.content.split("My Feedback: ")[0].replace("Question: ", "")}
                </Text>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} color="white">
                  {note.content.split("My Feedback: ")[1]}
                </Text>
              </>
            ) : (
              // Default text format for other notes
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight="600">
                {getTruncatedContent(note.content)}
              </Text>
            )}
          </Box>
        <Box
          backgroundColor="white"
          width="full"
          position="relative"
          bottom="0"
          left="0"
          right="0"
          boxSizing="border-box"
          textAlign="left"
          paddingLeft="7.5px"
        >
          <Text fontSize="sm" fontWeight="600" color="black" p={1}>
            {new Date(note.updatedAt).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Text>
        </Box>
      </Box>
    ) : null
  )}
</Masonry>
      </Box>
) : (
        // Layout for smaller screens (phone layout)
        <Box
        padding={0}
        maxWidth="4xl" // Adjust maximum width as needed
        mx="auto" // Centers the container
        className="notes-columns"
        pb="100px"
      >
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {filteredNotes.map((note) =>
              note.status === "ENABLED" && note.userId === userId ? (
                <Box
                  key={note.note_id}
                  borderWidth="1px"
                  borderRadius="10px"
                  overflow="hidden"
                  onClick={() => handleCardClick(note)}
                  cursor="pointer"
                  className="note-card"
                  borderColor="white"
                >
                  <Box padding={3} textAlign="left">
            {note.noteType === "ONBOARDING_QUESTION" ? (
              // Specific formatting for ONBOARDING_QUESTION
              <>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} lineHeight="1.2" fontWeight="bold" mb={4} color="white">
                  {note.content.split("My Answer: ")[0].replace("Question: ", "")}
                </Text>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} color="white">
                  {note.content.split("My Answer: ")[1]}
                </Text>
              </>
            ) : note.content.includes("Question:") && note.content.includes("My Feedback:") ? (
              // Specific formatting for notes with "Question:" and "My feedback:"
              <>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} lineHeight="1.2" fontWeight="bold" mb={4} color="white">
                  {note.content.split("My Feedback: ")[0].replace("Question: ", "")}
                </Text>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} color="white">
                  {note.content.split("My Feedback: ")[1]}
                </Text>
              </>
            ) : (
              // Default text format for other notes
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight="600">
                {getTruncatedContent(note.content)}
              </Text>
            )}
          </Box>
        <Box
          backgroundColor="white"
          width="full"
          position="relative"
          bottom="0"
          left="0"
          right="0"
          boxSizing="border-box"
          textAlign="left"
          paddingLeft="7.5px"
        >
          <Text fontSize="sm" fontWeight="600" color="black" p={1}>
            {new Date(note.updatedAt).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Text>
        </Box>
      </Box>
    ) : null
  )}
</Masonry>
      </Box>
      )
          }

      {/* Add Note Modal */}
      <Modal isOpen={isAddModalOpen} onClose={handleCloseModal} size="full">
        <ModalOverlay />
        <ModalContent
          backgroundColor="#1C1C1C"
          color="white"
          pt={10}
          pb={4}
          pl={4}
          pr={4}
        >
          {isSaving && (
            <Progress
              size="xs"
              isIndeterminate
              sx={{
                "&": {
                  backgroundColor: "#1C1C1C",
                },
                "& > div": {
                  backgroundColor: "#FF0050",
                },
              }}
            />
          )}
          <ModalCloseButton />

          {/* Header */}
          <Box textAlign="center" mb={4}>
            <Text fontSize="3xl" fontWeight="bold" letterSpacing="-3px">
              dots
            </Text>
          </Box>

          <Flex direction="column" width="100%" maxW="3xl" mx="auto" mt={4}>
            <Box
              borderWidth="2px"
              borderRadius="10px"
              overflow="hidden"
              borderColor="white"
            >
              <Box backgroundColor="white" textAlign="left" paddingLeft="7.5px">
                <Text fontSize="sm" fontWeight="600" color="black" p={1}>
                  Add a New Note
                </Text>
              </Box>
              <Box p={3}>
                <Textarea
                  placeholder="Type your note here..."
                  value={newNoteContent}
                  onChange={(e) => setNewNoteContent(e.target.value)}
                  maxLength={2000}
                  border="none"
                  resize="none"
                  height={["300px", "350px", "400px"]} // Responsive height: 200px on small screens, 300px on medium, 400px on large
                  color="white"
                />
                <Flex justifyContent="flex-end" alignItems="center" mt={4}>
                  <Box color="gray.400" mr={2}>
                    {newNoteContent.length} / 2000
                  </Box>
                  <Button
                    onClick={handleAddNote}
                    isLoading={isSaving}
                    colorScheme="red"
                    bg="#FF0050"
                    color="white"
                    fontWeight="bold"
                  >
                    Save Note
                  </Button>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </ModalContent>
      </Modal>

      {/* Modal should be here, within the parent Box */}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent
          backgroundColor="#1C1C1C"
          m={0}
          pt={10}
          pb={4}
          pl={4}
          pr={4}
        >
          {" "}
          {/* Set background color and remove margins */}
          {isDeleting && (
            <Progress
              size="xs"
              isIndeterminate
              sx={{
                "&": {
                  backgroundColor: "#1C1C1C",
                },
                "& > div": {
                  backgroundColor: "#FF0050",
                },
              }}
            />
          )}
          {isSaving && (
            <Progress
              size="xs"
              isIndeterminate
              sx={{
                "&": {
                  backgroundColor: "#1C1C1C",
                },
                "& > div": {
                  backgroundColor: "#FF0050",
                },
              }}
            />
          )}
          <ModalCloseButton color="white" />
          
          
          {/* Header */}
          <Box textAlign="center" mb={12}>
            <Text
              fontSize="3xl"
              fontWeight="bold"
              color="white"
              letterSpacing="-3px"
            >
              dots
            </Text>
          </Box>
    
                {/* Note Content */}
                {selectedNote && (
                  <Box maxW="3xl" mx="auto"> {/* Adjusted for consistency with the existing modal */}

                  {/* Tag for NOTE */}
                  {selectedNote.noteType === "NOTE" && (
                    <Box
                      bg="white"
                      borderRadius="full"
                      px={2}
                      py={1}
                      display="inline-block"
                      mb={4}
                      minWidth="auto" // Adjust for tag size
                    >
                      <Text color="#FF0050" fontWeight="bold" fontSize={{base: "sm", md: "md", lg: "md"}}>Note</Text>
                    </Box>
                  )}
                  {/* Tag and Content for ONBOARDING_QUESTION */}
                  {selectedNote.noteType === "ONBOARDING_QUESTION" && (
                    <>
                      <Box
                        bg="white"
                        borderRadius="full"
                        px={2}
                        py={1}
                        display="inline-block"
                        mb={4}
                        minWidth="auto" // Adjust for tag size
                      >
                        <Text color="#FF0050" fontWeight="bold" fontSize={{base: "sm", md: "md", lg: "md"}}>Q&A</Text>
                      </Box>
                      <Text fontSize={{base: "3xl", md: "2xl", lg: "3xl"}} lineHeight="1.2" fontWeight="bold" mb={4} color="white">
                        {/* Display the question part */}
                        {selectedNote.content.split("My Answer: ")[0].replace("Question: ", "")}
                      </Text>
                      <Text fontSize={{base: "md", md: "lg", lg: "xl"}} mb={24} color="white">
                        {/* Display the answer part */}
                        {selectedNote.content.split("My Answer: ")[1]}
                      </Text>
                    </>
                  )}
                  {/* Tag and Content for FEEDBACK */}
        {selectedNote.noteType === "FEEDBACK" && (
          <>
            <Box
              bg="white"
              borderRadius="full"
              px={2}
              py={1}
              display="inline-block"
              mb={4}
              minWidth="auto"
            >
              <Text color="#FF0050" fontWeight="bold" fontSize={{ base: "sm", md: "md", lg: "md" }}>External Ask</Text>
            </Box>
            <Text fontSize={{ base: "2xl", md: "2xl", lg: "3xl" }} lineHeight="shorter" fontWeight="bold" mb={8} color="white">
              {/* Display the question part */}
              {selectedNote.content.split("My Feedback: ")[0].replace("Question: ", "")}
            </Text>
            <Text fontSize={{ base: "md", md: "lg", lg: "xl" }} color="#FF0050" mb={0}>
                Your feedback was:
              </Text>
            <Text fontSize={{ base: "md", md: "lg", lg: "xl" }} mb={24} color="white">
              {/* Display the feedback part */}
              {selectedNote.content.split("My Feedback: ")[1]}
            </Text>
          </>
        )}
                  {/* Default Content for NOTE */}
                  {selectedNote.noteType === "NOTE" && (
                    <>
                      <Textarea
                        value={noteContent}
                        onChange={(e) => setNoteContent(e.target.value)}
                        placeholder="Type your note here..."
                        fontSize="md"
                        mb={1}
                        color="white"
                        border="1px solid white"
                        backgroundColor="transparent"
                        borderRadius="md"
                        maxLength={2000}
                        height={["300px", "350px", "400px"]} // Responsive height matching the original modal
                        resize="none"
                        width="100%"
                      />
                      {/* Flex container to align character counter and save button */}
                      <Flex justifyContent="flex-end" alignItems="center" mt={4}>
                        <Box color="gray.400" mr={2}>
                          {noteContent.length} / 2000
                        </Box>
                        <Button
                          onClick={handleUpdateNote}
                          isLoading={isSaving}
                          colorScheme="red"
                          bg="#FF0050"
                          color="white"
                          fontWeight="bold"
                          isDisabled={noteContent === (selectedNote?.content || '')}
                        >
                          Save Changes
                        </Button>
                      </Flex>
                    </>
                  )}
                </Box>
              )}
          {/* Floating Delete Note Button */}
          <Box 
            position="fixed" 
            bottom="0" 
            left="0" 
            right="0" 
            bg="#1c1c1c" 
            p={4} 
            textAlign="center"
            zIndex="modal" // Use a high z-index value, "modal" is typically a high value in theme settings
          >
            <Button
              width={{ base: "90%", md: "70%", lg: "30%" }}
              mx="auto"
              onClick={() => handleDeleteNote(selectedNote.noteId)}
              backgroundColor="#FF0050"
              color="white"
              height={{ base: "56px", md: "58px", lg: "60px" }}
              borderRadius="30px"
              _hover={{ bg: "#ef004b" }}
              fontSize={{ base: "md", md: "md", lg: "xl" }}
            >
              Delete Note
            </Button>
          </Box>
          </ModalContent>
</Modal>
                    <Box position="fixed" bottom="0" left="0" right="0" bg="#1c1c1c" p={4} textAlign="center">
                      <Button
                        width={{ base: "90%", md: "70%", lg: "30%" }}
                        mx="auto"
                        onClick={onAddModalOpen}
                        backgroundColor={{ base: "#FF0050", md: "#FF0050", lg: "#FF0050"}}
                        color={{ base: "#white", md: "white", lg: "white" }}
                        height={{ base: "56px", md: "58px", lg: "60px" }}
                        borderRadius={{ base: "30px", md: "30px" }}
                        _hover={{bg: { md: "#ef004b" }}}
                        fontSize={{base: "sm", md:"md", lg:"lg"}}
                      >
                        + Add a Note
                      </Button>
                    </Box>
                   </div>
                );
              }

export default Notes;
