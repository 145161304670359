//onBoardingQuestions.tsx

import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Button, Textarea, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@chakra-ui/icons';
import { useAuth } from '../auth/AuthContext'; // Adjust the path as needed
import Cookies from 'js-cookie';


const OnBoardingQuestions = () => {
  const [responses, setResponses] = useState(Array(10).fill('')); // Adjust the size as needed
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const displayedQuestionCount = 5;
  const numberOfQuestions = displayedQuestionCount;
  const [displayName, setDisplayName] = useState('');
  const [uniqId, setUniqId] = useState('');
  const isAnyResponseTooShort = responses.some(response => response.length < 4);
  const submitButtonColor = isAnyResponseTooShort ? 'gray' : '#FF0050';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const userDotLink = `https://dotspot.ai/${uniqId}`;
  const { authState } = useAuth();
  // const userId = authState.userId; // Get userId from authentication state
  const userId = Cookies.get('userId')
  console.log("cookies userId: ", Cookies.get('userId'))


  useEffect(() => {
    console.log('Current authState.userId:', authState.userId);
    const fetchDisplayName = async () => {
      //if (!authState.userId) return;
      if (!userId) return;
      try {
        const response = await fetch(`/api/getDisplayName/${userId}`);
        if (!response.ok) throw new Error('Failed to fetch display name');
  
        const data = await response.json();
        setDisplayName(data.displayName);
        setUniqId(data.uniqId);
      } catch (error) {
        console.error('Error fetching display name:', error);
      }
    };
  
    fetchDisplayName();
    const savedResponses = JSON.parse(localStorage.getItem('onboardingResponses'));
    if (savedResponses && savedResponses.length === displayedQuestionCount) {
      setResponses(savedResponses);
    }

//}, [authState.userId]);
}, [userId]);

  // Hardcoded questions (you can replace these with your actual questions)
  const questions = [
    "Add 3 books, tv shows, or movies.",
    "Add 3 hobbies or activities.",
    "Add 3 songs or music artists.",
    "Add 3 restaurants or coffee shops.",
    "Add 3 things you're looking for (ie date ideas, planning a trip, information on niche topics, etc).",
    "What’s your go-to book, music, or movie recommendation and what sticks out about it?",
    "What is your most unusual or unexpected hobby or interest?",
    "What is your ideal weekend routine?",
    "Tomorrow is your last day to live. What will you regret not having done?",
    "What skill or knowledge do you wish you possessed that you currently don’t?",
    "📱 Go to your keyboard emojis and select the 5 emojis in the first column of Frequently Used emojis.",
    "📚 What's your go-to book, music, or movie recommendation and what sticks out about it?",
    "⏳ Tomorrow is your last day to live. What will you regret not having done?",
    "🍽️ What's your favorite type of food or restaurant and why?",
    "😂 How would your best friend describe you?",
    "☀️ Describe your morning routine, be as detailed as possible.",
    "💭 If you could have dinner with any three people, dead or alive, who would they be and why?",
    "🍿 Which movie(s) have you seen more than two times?",
    "👽 What’s the weirdest thing about you?",
    "💸 If you had unlimited money and time, what would you do with your life?",
    // ... more questions ...
  ];

  const copyLinkToClipboard = async (link) => {
    try {
      await navigator.clipboard.writeText(link);
      setIsLinkCopied(true);
      toast({
        title: 'Link Copied',
        description: 'The link has been copied to your clipboard.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to copy the link:', error);
      // Handle error (e.g., display error toast)
    }
  };

  // Adjust the number of responses and questions based on the displayedQuestionCount
  questions.length = displayedQuestionCount;
  responses.length = displayedQuestionCount;

  const handleChange = (index, value) => {
    const updatedResponses = [...responses];
    updatedResponses[index] = value;
    setResponses(updatedResponses);
    localStorage.setItem('onboardingResponses', JSON.stringify(updatedResponses));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    // Use userId from authState
  if (!userId) {
    toast({
      title: 'Error',
      description: 'User ID not found. Please log in again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    setIsSubmitting(false);
    return;
  }

    // Check if any response is shorter than 4 characters
    if (isAnyResponseTooShort) {
      toast({
        title: 'Error',
        description: 'Make sure each response is at least 4 characters long!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

  // Concatenate question and answer
  const concatenatedResponses = responses.map((response, index) => `Question: ${questions[index]} My Answer: ${response}`);
    try {
      const response = await fetch(`/api/onboardingResponses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, concatenatedResponses }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save onboarding responses.');
      }

      // New code to update isOnboardingComplete status
    const updateStatusResponse = await fetch(`/api/user/updateOnboardingStatus`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });

    if (!updateStatusResponse.ok) {
      throw new Error('Failed to update onboarding status.');
    }

    localStorage.removeItem('onboardingResponses');
    setIsModalOpen(true); // Open the modal

    } catch (error) {
    } 
  };

  const handleNavigate = () => {
    // Additional logic if needed
    sessionStorage.setItem('completedOnboarding', 'true');
    // navigate('/appcontent', { state: { fromOnboarding: true } });
    navigate('/', { state: { fromOnboarding: true } });
  };
  

  return (
    <>
      {/* Dots Header */}
      <Box textAlign="center" borderBottom="0.5px solid gray" pb={2} pt={8}>
        <Text fontSize="3xl" fontWeight="bold" letterSpacing="-3px" color="white">
          dots
        </Text>
      </Box>

      {/* Additional Text */}
      <Flex direction="column" width="100%" maxW="2xl" mx="auto" mt={8} px={4} textAlign="left">
        <Box >
          <Text fontSize="4xl" fontWeight="bold" color="white" lineHeight={1.1}>
              <span style={{ color: '#FF0050' }}>{displayName}</span>, add your interests to get started
          </Text>
        </Box>
      </Flex>

        {/* Questions */}
        <Flex direction="column" textAlign="left" width="100%" maxW="2xl" mx="auto" mt={4} px={4}
        fontSize={{ base: "md", md: "md", lg: "xl" }} lineHeight="shorter">
          {questions.map((question, index) => (
            <Box key={index} mb={6} mt={6}>
              {/* Question Text */}
              <Text fontSize={{ base: "md", md: "lg", lg: "xl" }} fontWeight="bold" color="white" mb={4} ml={2}>
                {question}
              </Text>
             {/* Textarea and Counter Box */}
      <Box position="relative">
        <Textarea
          value={responses[index]}
          onChange={(e) => handleChange(index, e.target.value)}
          placeholder="Type your answer here..."
          fontSize={{ base: "md", md: "md", lg: "xl" }}
          _placeholder={{ color: 'gray.500' }}
          maxLength={500}
          resize="none"
          height={["200px", "200px", "200px"]}
          color="black"
          bg="white"
          border="none"
          borderTopRadius="20px"
          borderBottomRadius="0px" // Rounded top corners
        />
        {/* Character Counter Box */}
        <Flex justifyContent="space-between" alignItems="center" bg="white" px={4} py={2} borderBottomRadius="20px">
          {/* Character Counter */}
          <Text color="black" ml="auto">
            {`${responses[index].length}/500`}
          </Text>
                     {/* Checkmark UI */}
                    {responses[index].length >= 20 && (
                      <Box borderRadius="full" bg="#FF0050" width="30px" height="30px" display="flex" alignItems="center" justifyContent="center" ml={3}>
                        <CheckIcon color="white" />
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Box>
            ))}
          </Flex>

      <Flex justifyContent="center" mt={4} pb={12}> {/* Adding bottom padding to the Flex container */}
      <Button
        onClick={handleSubmit}
        isLoading={isSubmitting}
        loadingText="Submitting..."
        bg={submitButtonColor}
        color="white"
        fontWeight="bold"
        paddingY="8"
        width={{ base: "90%", md: "90%", lg: "100%" }}
        maxW="xl" // Set the max width to match the text boxes
        _active={{ bg: submitButtonColor }}
        _hover={{ bg: submitButtonColor }}
        isDisabled={isAnyResponseTooShort} // Disable the button if any response is too short
        borderRadius="full"
        fontSize={{ base: "md", md: "md", lg: "lg" }}
        height={{ base: "48px", md: "56px", lg: "68px" }}
      >
        Submit answers
      </Button>
      </Flex>

      <Modal isOpen={isModalOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay
          bg="blackAlpha.900" // Adjust the background color and opacity
          />
      <ModalOverlay />
      <ModalContent 
        bg="#1C1C1C"
        maxW="800px"
        mx="auto"
        borderRadius="30px" // This will make the borders rounder
        p={{ base: "2", md: "4", lg: "4" }}
        >
        <ModalHeader color="white">
        <Text textAlign="left" fontWeight="bold" lineHeight="shorter" mt={{ base: 3, md: 3, lg: 3 }} mb={{ base: 1, md: 3, lg: 3 }} fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
                    Your Dot is ready!
                  </Text>
        </ModalHeader>
        <ModalBody color="white">
        <Text textAlign="left" fontSize={{ base: "md", md: "md", lg: "xl" }} lineHeight="shorter" mb={{ base: 4, md: 4, lg: 8 }}>
              Copy and share your link below to have the world interact with you!
            </Text>
            <Flex justifyContent="center" alignItems="center">
              <Box 
                  as="button" 
                  onClick={() => copyLinkToClipboard(userDotLink)}
                  bg="#FF0050" 
                  p={4} 
                  px={2}
                  fontWeight="bold"
                  borderRadius="full"
                  _hover={{ bg: "#ef004b", cursor: "pointer" }}
                  width={{ base: "100%", md: "100%", lg: "100%" }}
                  maxW="2xl" // Set the max width to match the text boxes
                  fontSize={{ base: "md", md: "md", lg: "xl" }}
                  whiteSpace="normal" // Allow text wrapping
                  wordBreak="break-word" // Break the text to prevent overflow
              >
                  {userDotLink}
              </Box>
          </Flex>
                    <Text textAlign="left" fontSize={{ base: "md", md: "md", lg: "xl" }} lineHeight="
                      shorter" mb={{ base: 4, md: 4, lg: 5 }} mt={{ base: 4, md: 4, lg: 8 }}>
                      After sharing your link: 
                      </Text>
                                    <style>
                  {`
                      .responsive-list {
                          list-style-position: outside;
                          padding-left: 1.5em;
                          text-align: left;
                      }
                    
                      .responsive-list li {
                          font-size: 1rem; /* Equivalent to 'md' */
                          line-height: 1.4; /* A shorter line height */
                          margin-bottom: 1em;
                      }
                    
                      @media (min-width: 768px) {
                          .responsive-list li {
                              font-size: 1.25rem; /* Equivalent to 'xl' */
                              line-height: 1.6; /* A slightly larger line height for readability */
                          }
                      }
                  `}
              </style>
                    
              <ol className="responsive-list">
                  <li>
                  Go to <strong>Ask</strong> to interact with your own Dot. You can ask yourself questions, discover yourself on a deeper level, and get great recommendations on things to do and places to visit.
                  </li>
                  <li>
                  Go to <strong>Notes</strong> to see your responses to your onboarding questions and add more information about yourself. The more you share, the better your Q&A and recommendations will be!
                  </li>
                  <li>
                  Go to <strong>Inbox</strong> to see all the questions people are asking you. Your Dot may get answers wrong initially - add feedback to improve it. You’ll never have to repeat yourself.
                  </li>
              </ol>
        </ModalBody>
        <ModalFooter justifyContent="center">
        <Button
          onClick={handleNavigate}
          fontWeight="bold"
          //_hover={{ bg: "white", textColor: "black"}}
          size="lg"
          textColor={"black"}
          bg="white"
          border="2px" // Set the border width
          borderColor="white" // Set the border color
          borderRadius="full" // Fully rounded corners
          width={{ base: "100%", md: "100%", lg: "100%" }}
          maxW="2xl" // Set the max width to match the text boxes
          fontSize={{ base: "md", md: "md", lg: "xl" }}
          height={{ base: "54px", md: "58px", lg: "64px" }}
          mb={4}
        >
          Go to your Dot!
        </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
};

export default OnBoardingQuestions;
