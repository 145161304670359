import { useState, useRef, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import Markdown from "react-markdown";
import Cookies from 'js-cookie';
import { useAuth } from '../auth/AuthContext'; // Adjust the path as needed
import { AutoResizeTextarea } from './AutoResizeTextarea'; // Import your custom component
import {
  Input,
  Button,
  Flex,
  Fade,
  Box,
  Spacer,
  Text,
  Spinner,
  Avatar,
  VStack,
  HStack,
  InputGroup,
  Divider,
  InputRightElement,
  Link as ChakraLink,
  useBreakpointValue,
  Image
} from "@chakra-ui/react";
import { MdAddAPhoto } from "react-icons/md"; // Icon for the profile picture upload button

const ANONYMOUS_USER_UUID = "db8b91c5-f6ca-45ba-9328-5c793e536ccd";

const AskLink = () => {
  //const { userId } = useParams();
  const { authState } = useAuth();
  //const askerUserId = Cookies.get('userId');
  const askerUserId = Cookies.get('userId') || ANONYMOUS_USER_UUID; // Get userId from authentication state
  // Get userId from authentication state
  const { uniqId } = useParams(); // Changed from userId to uniqId
  const [receiverUserId, setReceiverUserId] = useState(null); // Add this state
  const [askeeData, setAskeeData] = useState({ displayName: '', bioInterests: [], profilePic: '', email: ''});
  const [askerData, setAskerData] = useState({ profilePic: 'https://dots-storage-bucket.s3.amazonaws.com/dotsemailanonymous.png' }); // Default for anonymous user
  const interests = ["building computers", "traveling the world", "drinking coffee"];
  const [query, setQuery] = useState("");
  const [answerText, setAnswerText] = useState(""); // Add this state
  const [response, setResponse] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchController = useRef(new AbortController());
  const [currentInterestIndex, setCurrentInterestIndex] = useState(0);
  const sidePadding = useBreakpointValue({ base: '4', lg: '20%' });
  const [isAskingQuestion, setIsAskingQuestion] = useState(false); // For toggling the send/stop button
  const [isRequesting, setIsRequesting] = useState(false);
  const [streamCompleted, setStreamCompleted] = useState(false);
  const [askId, setAskId] = useState(null); // Initial state is null indicating no askId is available yet

  useEffect(() => {
    const fetchAskeeData = async () => {
      try {
        // First, fetch the userId using the uniqId
        const userIdRes = await fetch(`/api/getUserId/${uniqId}`);
        if (!userIdRes.ok) {
          throw new Error("Failed to fetch userId");
        }
        const { userId } = await userIdRes.json();
        setReceiverUserId(userId); // Set the userId in state

        // Then, fetch user data using the userId
        const userRes = await fetch(`/api/user/${userId}`);
        if (userRes.ok) {
          const data = await userRes.json();
          setAskeeData({
            displayName: data.displayName,
            bioInterests: data.bioInterests,
            profilePic: data.profilePic,
            email: data.email
          });
          console.log('Askee data:', data);
          // Fetch the user's email using the new endpoint
          const emailRes = await fetch(`/api/getEmail/${userId}`);
          if (emailRes.ok) {
            const emailData = await emailRes.json();
            // Update state to include email, ensuring to preserve existing askeeData
            setAskeeData(prevData => ({
              ...prevData,
              email: emailData.email
            }));
          } else {
            throw new Error("Failed to fetch user email");
          }
        } else {
          throw new Error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error:", error);
        setError(error.message);
      }
    };
  
    const fetchAskerData = async () => {
      if (askerUserId && askerUserId !== ANONYMOUS_USER_UUID) {
        try {
          const res = await fetch(`/api/user/${askerUserId}`);
          if (res.ok) {
            const data = await res.json();
            setAskerData({ 
              profilePic: data.profilePic,
              displayName: data.displayName
             });
          } else {
            setAskerData({
              //profilePic: 'https://dots-storage-bucket.s3.amazonaws.com/dotsemailanonymous.png', // Default for anonymous or failed fetch
              displayName: 'Unknown User' // You might want to handle this case differently
          });
          }
        } catch (error) {
          console.error("Error fetching asker data:", error);
          // Handle error appropriately, maybe setting default or error state
          setAskerData({
              //profilePic: 'https://dots-storage-bucket.s3.amazonaws.com/dotsemailanonymous.png', // Default for error fetching Asker data
              displayName: 'Unknown User' // Fallback displayName for error
          });
      }
  } else {
      // Handle the anonymous user case
      setAskerData({
          //profilePic: 'https://dots-storage-bucket.s3.amazonaws.com/anonymous_you.png', // "You profile pic"
          //profilePic: 'dots-storage-bucket.s3.amazonaws.com/you_anonymous_pic.png', // "YOU all caps profie pic"
          displayName: 'Anonymous' // Display name for anonymous users
      });
  }
};
  
    if (uniqId) {
      fetchAskeeData();
    }
    fetchAskerData(); // Fetch data for the asker regardless of userId, because Asker might be different from Askee
  
    const interval = setInterval(() => {
      setCurrentInterestIndex(prevIndex => {
        const nextIndex = (prevIndex + 1) % interests.length;
        return nextIndex;
      });
    }, 4000);
  
    return () => clearInterval(interval);
  }, [uniqId, askerUserId]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleAskClick = async () => {
    if (isAskingQuestion) {
      fetchController.current.abort();
      setIsRequesting(false);
      setIsLoading(false);
      setIsAskingQuestion(false);
      setStreamCompleted(false);
    } else {
      setStreamCompleted(false); // Ensure streamCompleted is reset at the start of a new question
      setIsRequesting(true); // Start the requesting phase
      setIsLoading(true); // Ensure streaming indicator is not active yet
      setIsAskingQuestion(true); // Indicate that a question process has started
      setResponse("");
  
      fetchController.current.abort(); // Abort any ongoing request
      fetchController.current = new AbortController();
      const { signal } = fetchController.current;
  
  
      try {
        // Set receiverUserId to the userId from the uniqId parsed from the URL
        // Use receiverUserId from state
          if (!receiverUserId) {
          throw new Error("Receiver user ID not found");
           }
  
        // Set askerUserId to the logged-in user's ID, or to ANONYMOUS_USER_UUID if not logged in
        //const askerIdForRequest = askerUserId || ANONYMOUS_USER_UUID;
  
        const res = await fetch(`/api/askBio`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
          askerUserId: askerUserId || ANONYMOUS_USER_UUID,
          receiverUserId,
          query,
          displayName: askeeData.displayName,
          askerDisplayName: askerData.displayName, 
        }), 
          signal,
        });
  
        if (!res.ok || !res.body) {
          throw new Error("Network response was not ok");
        }

        setIsRequesting(false); // Requesting phase ends, response begins to stream
        setIsLoading(false); // Streaming phase starts
  
        let accum = "";
        const decoder = new TextDecoder();
  
        await res.body.pipeTo(
          new WritableStream({
            write(chunk) {
              const chunkContent = decoder.decode(chunk);
              accum += chunkContent;
              console.log("Chunk Content:", chunkContent);
              setResponse(prevResponse => prevResponse + chunkContent);
            },
            async close() {
              console.log("Final Response:", accum);
              setAnswerText(accum);
              localStorage.setItem('cachedResponseAskLink', accum);
              setStreamCompleted(true);
            // Once the final response is received, send an email

            },
            abort(err) {
                console.error("Stream aborted:", err);
                setStreamCompleted(false);
            },
      })
          );
          } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Fetch error:", error);
            setError(error.message || "Failed to fetch response. Please try again.");
          }
          } finally {
          //setIsLoading(false);
          setIsRequesting(false);
          setIsAskingQuestion(false);
          setIsStreaming(false);
          }
        }
      };

      useEffect(() => {
        const fetchAndSendEmail = async () => {
          if (streamCompleted && receiverUserId && (askerUserId || askerUserId === ANONYMOUS_USER_UUID)) {
            try {
              const fetchedAskId = await fetchAskId(receiverUserId, askerUserId, query);
              if (fetchedAskId) {
                console.log("Retrieved askId:", fetchedAskId);
                await sendEmail({
                  askId: fetchedAskId,
                  askerDisplayName: askerData.displayName,
                  askerProfilePic: askerData.profilePic,
                  askeeEmail: askeeData.email,
                  askeeDisplayName: askeeData.displayName,
                  askText: query,
                  response: answerText,
                });
              } else {
                console.error("Failed to fetch askId.");
              }
            } catch (error) {
              console.error("Error in fetching askId or sending email:", error);
            }
          } else {
            console.log("Not all conditions for sending email are met.");
          }
        };
        fetchAndSendEmail();
      }, [streamCompleted, receiverUserId, askerUserId, query, askerData, askeeData.email, askeeData.displayName, answerText]);
      
      

            const fetchAskId = async (receiverUserId, askerUserId, query) => {
              // Ensure receiverUserId and askerUserId are not null before proceeding
              if (!receiverUserId || !askerUserId) {
                  console.error("Missing receiverUserId or askerUserId");
                  return;
              }
            
              const queryParams = new URLSearchParams({
                  askerUserId: askerUserId,
                  receiverUserId: receiverUserId,
                  query
                  //answerText: answerText // Including answerText in the query
              }).toString();
            
              try {
                  const response = await fetch(`/api/getAskId?${queryParams}`);
                  if (!response.ok) {
                      throw new Error("Failed to fetch askId");
                  }

                  const { askId } = await response.json();
                  console.log("Retrieved askId:", askId);
                  // Use askId as needed
                  return askId;
              } catch (error) {
                  console.error("Error fetching askId:", error);
                  return null; // Ensure function returns null on error
              }
          };

          const sendEmail = async (emailDetails) => {
            console.log('Sending email with details:', emailDetails);
          
            try {
              // Sending the email including the askId
              const response = await fetch('/api/sendEmail', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(emailDetails),
              });
          
              if (!response.ok) {
                throw new Error('Failed to send email');
              }
              console.log('Email sent successfully with askId:', emailDetails.askId);
            } catch (error) {
              console.error('Error in sending email:', error);
            }
          };


    const handleStopClick = () => {
      fetchController.current.abort(); // Abort the fetch operation
      setIsLoading(false); // Reset the loading state
      setIsStreaming(false); // Ensure streaming is stopped
      setIsAskingQuestion(false); // Stop loading when stopping the question process
    };


  //const Header = () => {
  //  const { authState } = useAuth();
  //  const isLoggedIn = authState.userId !== null; // Check if the user is logged in based on the global auth state

  const Header = () => {
    const userId = Cookies.get('userId'); // Get userId from cookies
    const isLoggedIn = userId !== undefined; // Check if the user is logged in based on userId presence
      
    return (
      <Flex
        alignItems="center"
        p={4}
        bg="#1C1C1C"
        borderBottom="1px"
        borderColor="#5C5C5C"
        pl={sidePadding} // Padding left on large screens
        pr={sidePadding} // Padding right on large screens
      >
        <ChakraLink as={Link} to="/about" color="white">About</ChakraLink>
        <Spacer />
        <Text fontSize="3xl" fontWeight="bold" letterSpacing="-3px" color="white" position="absolute" left="50%" transform="translateX(-50%)">
          dots
        </Text>
        <Spacer />
        <Button 
          as={Link} 
          to={isLoggedIn ? "/" : "/signup"} 
          colorScheme="pink" 
          backgroundColor="white" 
          textColor="#FF0050" 
          borderRadius="full"
          _hover={{ bg: 'white', textColor: "#FF0050" }}>
          {isLoggedIn ? "Home" : "Sign Up"}
        </Button>
      </Flex>
    );
  };
  
  const getTypeWriterClass = (interest) => {
    if (interest.length < 10) {
      return 'typewriter_fast';
    } else if (interest.length < 20) {
      return 'typewriter_medium';
    } else {
      return 'typewriter_slow';
    }
  }
  

  return (
    <>
    <Header />
    <VStack spacing={4} align="center" p={4} pt={10} mx="auto" maxWidth="lg">
        {/* Profile Pictures */}
        <HStack justifyContent="center" position="relative" width="100%">
         {/* Askee Avatar */}
        <Avatar
          name={askeeData.displayName}
          //src={askeeData.profilePic || `${__ENDPOINT__}/assets/profile_pic_anon.png`}
          src={askeeData.profilePic}
          size="lg"
          position="relative"
          //transform="translateX(-30%)" 
          zIndex="2"
          bg="#C6C6C6" // Background color of the Avatar
          color="gray.800" // Text color for the initials
        />
  </HStack>

    
        {/* Name of the Askee */}
        <Text mt={-2} fontSize="xl" color="white">
          <span >{askeeData.displayName}</span><span style={{fontWeight: 'normal'}}></span>
        </Text>
      
         {/* Ask query input bar with integrated Ask button */}
      <InputGroup size="md" w="full">
      <AutoResizeTextarea
                  placeholder="Ask me anything"
                  fontSize={{ base: "16px", md: "md", lg: "lg" }}
                  value={query}
                  onChange={handleInputChange}
                  color="black"
                  backgroundColor="white"
                  border="none"
                  _placeholder={{ color: "gray.500" }}
                  paddingEnd="4.5rem" // Ensure enough padding for the button
                  minH={{ base: "50px", md: "52px", lg: "56px" }} // Minimum height
                  lineHeight="1.2" // Adjust line height for vertical centering
                  //paddingTop="20px" // Adjust top padding for vertical centering
                  //paddingBottom="20px" // Adjust bottom padding for vertical centering
                  pt="18px"
                  pb="18px" // Top padding to vertically center the placeholder text
                  borderRadius="30px"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {  // Checks if Enter key is pressed without the Shift key
                      e.preventDefault();  // Prevents the default action of inserting a newline
                      handleAskClick();  // Call the handleAskClick function
                    }
                  }}
                />
                <Button
                  size="sm"
                  //onClick={handleAskClick}
                  colorScheme="pink"
                  backgroundColor="#FF0050"
                  _hover={{ bg: "#FF3371" }}
                  onClick={isAskingQuestion ? handleStopClick : handleAskClick}
                  //isLoading={isLoading}
                  borderRadius="full"
                  position="absolute"
                  right="10px" // Align to the right
                  bottom="12px" // Align to the bottom, adjusted to center
                  height="30px" // Fixed height
                  width="44px" // Fixed width
                  zIndex="2" // Assign a higher z-index
                >
                  {isAskingQuestion ? (
                  <Image src="/assets/ask_stop_icon.svg" alt="Stop" boxSize="16px"/>
                  ) : (
                    <Image src="/assets/ask_send_icon.svg" alt="Send" boxSize="22px"/>
                  )}
                </Button>
              </InputGroup>
              {isLoading && (
                <Spinner
                  thickness="4px"
                  speed="0.75s"
                  emptyColor="#1c1c1c"
                  color="#FF0050"
                  size="md"
                />
              )}
                  {response && (
            <Box mt={4} p={4} pb={10} bgColor="transparent" color="white" alignSelf="flex-start" className="markdown-content">
              <div style={{ textAlign: "left" }}>
              <Markdown color="white">{response + (isStreaming ? " ⚪" : "")}</Markdown> 
              </div>
            </Box>
          )}
        {error && (
          <Text mt={4} color="red.500">
            {error}
          </Text>
        )}
            {/* Add a Box as a spacer */}
            <Box height="100px" width="100%"></Box> {/* Adjust height as needed to create more space */}       

           {/* Divider line added here */}
      <Divider borderColor="white" borderWidth={0.3} width="100%" />

        {/* Permanent text box with indented bullet points added below */}
        <Box p={2} pt={0} mt={0} w="full" color="white" textAlign="left">
            <Text fontSize="lg" fontWeight="bold" mt={0}>What is this?</Text>
            <ol style={{ paddingLeft: '30px', marginTop: '5px' }}>  {/* Apply the same style as the bullet points */}
              <li>
                <Text mt={3}>Dots is the fastest way to get to know someone. It allows you to talk to anyone instantly in any language.</Text>
              </li>
              <li>
                <Text mt={3}>You can get to know people by asking them questions. Remember - there may be some details they haven't shared yet. It can occasionally get some things wrong as well.</Text>
              </li>
              <li>
                <Text mt={3}>Your questions are anonymous. If you sign up for your own account, you can receive updates to the questions you ask.</Text>
              </li>
            </ol>
            <Text mt={5}>Some example questions you can try:</Text>
            <ul style={{ paddingLeft: '30px', marginTop: '5px' }}>  {/* Keep the existing indentation and spacing for the bullet points */}
              <li>Give me a summary about yourself?</li>
              <li>What are your dreams in life?</li>
              <li>What are your favorite books and why?</li>
              <li>Where should we go on a date?</li>
            </ul>
          </Box>
      </VStack>
      </>
    );    
};

export default AskLink;