// AuthStateHandler.tsx
declare var __ENDPOINT__: string;

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { auth } from "./firebaseConfig";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { Input, Text, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import { useAuth } from './AuthContext';

let runOnce = true;

const AuthStateHandler = () => {
  const { setAuthInfo, checkUserOnboardingStatus, authState } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [showEnterEmailModal, setShowEnterEmailModal] = useState(false);
  const [emailForSignIn, setEmailForSignIn] = useState('');

  // Replace local storage with cookies for user ID management
  const storeUserId = (userId) => {
    Cookies.set('userId', userId, { expires: 7, path: '/', secure: true, sameSite: 'Strict' });
  };

  const removeUserId = () => {
    Cookies.remove('userId');
  };

  // Handle email confirmation
  const handleEmailConfirm = () => {
    if (!validateEmail(emailForSignIn)) {
      alert("Please enter a valid email address."); // You can replace this with a more sophisticated error handling
      return;
    }
    if (emailForSignIn) {
      Cookies.set('emailForSignIn', emailForSignIn, { expires: 7, secure: true, sameSite: 'Strict' }); // Set cookie for 7 days
      setShowEnterEmailModal(false);
      window.location.reload(); // Reload to trigger the sign-in process
    }
  };

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };


  // Function to fetch user details
  const fetchUserDetails = async () => {
    const firebaseUserId = Cookies.get('userId'); // Assuming this is the Firebase User ID
    if (!firebaseUserId) throw new Error("User ID not found");

    const userDetailsResponse = await fetch(`/api/getUserDetails`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ firebaseUserId }),
    });

    if (!userDetailsResponse.ok) throw new Error(`Failed to fetch user details: ${userDetailsResponse.status}`);

    const userDetails = await userDetailsResponse.json();
    return userDetails;
  };

  // Function to send the sign-in link
  async function sendSignInLink(email) {
    // Configure the action URL, which is where the user will be redirected after clicking the sign-in link
    const actionCodeSettings = {
      // Set your URL where the user will be redirected after clicking the link
      url: `/`,
      handleCodeInApp: true,
    };

    try {
      await auth.sendSignInLinkToEmail(email, actionCodeSettings);
      // Store the email in a cookie to use it later for signing in
      Cookies.set('emailForSignIn', email, { expires: 7, secure: true, sameSite: 'Strict' }); // Set cookie for 7 days
    } catch (error) {
      console.error('Error sending sign-in link:', error);
      // Handle error (e.g., show a message to the user)
    }
  }

  useEffect(() => {
    // Check if the current URL is a sign-in link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = Cookies.get("emailForSignIn");
      // if (!email) {
      //   setShowEnterEmailModal(true);
      //   return;
      // }

      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          Cookies.remove('emailForSignIn'); // Clear the email cookie
          const idToken = await result.user.getIdToken();
          const authResponse = await fetch(`/api/authenticate`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idToken }),
            credentials: 'include', // Ensure credentials are included for cookies
          });
          if (!authResponse.ok) {
            throw new Error(`Failed to authenticate user: ${authResponse.status}`);
          }

          const sessionToken = await authResponse.text();
          console.log(sessionToken, 'sessionToken')
          Cookies.set('sessionToken', idToken, { expires: 7, secure: true, sameSite: 'Strict' }); // Store session token in cookie
          setAuthInfo({ userId: result.user.uid }); // Update the global auth state with the new userId
        })
        .catch((error) => {
          console.error('Error during sign-in:', error);
        });
    }
  }, [auth, navigate]);

  useEffect(() => {

    const unregisterAuthObserver = auth.onAuthStateChanged(async (firebaseUser: any) => {
      if (!!authState.userId && !runOnce) {
        return;
      }
      if(!!authState.userId) {
        runOnce = false;
      }

      if (firebaseUser == null) {
        const currentPath = location.pathname;
        // Directly list paths that require authentication
        const protectedPaths = ['/appcontent', '/completeDisplayName', '/completeOnboardingQuestions', '/'];
        // Extend check to include dynamic /ask/:askId path
        const isAskPath = currentPath.startsWith("/ask/");
        // Check if the current path is one of the protected paths
        const isProtectedPath = protectedPaths.includes(currentPath) || isAskPath;
        // Allow access to /signup and /:uniqId (dynamic paths not matching protected paths)
        const isAllowedUnauthPath = currentPath === "/signup" || !isProtectedPath;
        if (!isSignInWithEmailLink(auth, window.location.href) && !isAllowedUnauthPath) {
          navigate("/signup");
        }
      } else {
        console.log("firebaseUser", firebaseUser)
        if (firebaseUser.uid) {
          const firebaseUserId = firebaseUser.uid
          // if (!firebaseUser.displayName && currentPath === '/completeDisplayName') {
          //   navigate('/completeDisplayName');
          // }
          
          console.log("endpoint", __ENDPOINT__);

          try {
            const userIdResponse = await fetch(`/api/getUserId`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ firebaseUserId: firebaseUser.uid }),
              //body: { firebaseUserId: firebaseUser.uid },
              //body: JSON.stringify({ firebaseUserId })
            });
            console.log("userIdResponse", userIdResponse);
            console.log("userIdResponse", firebaseUser.uid);

            if (!userIdResponse.ok) {
              throw new Error(`Failed to fetch user UUID: ${userIdResponse.status}`);
            }

            const data = await userIdResponse.json();
            console.log("Received userId from backend:", data, data?.user?.id);
            storeUserId(data?.user?.userId);
            setAuthInfo({ userId: data.user.userId }); // Update the global auth state with the new userId
            checkUserOnboardingStatus;
            console.log("AuthContext updated with userId:", data?.user);

            const userDetails = data.user;
            console.log("userDetails", userDetails);

            if (!userDetails?.displayName) {
              navigate('/completeDisplayName');
            }
            else if (!userDetails.isOnboardingComplete) {
              navigate('/completeOnboardingQuestions');
            } else if (location.state?.fromOnboarding) {
              // Redirect to /appcontent only if coming from onboarding
              navigate('/');
            }

            // Remove or modify else clause to prevent auto-redirect to /appcontent
          } catch (error) {
            console.error("Error during user authentication and detail retrieval:", error);
          }
        }
      }
    });
    //console.log("counting ", setAuthInfo, location.pathname, currentPath);
    return () => unregisterAuthObserver();
  }, [auth, authState.userId]);

  return (
    <>
      <Modal isOpen={showEnterEmailModal}>
        <ModalOverlay />
        <ModalContent bgColor="#1c1c1c" color="white">
          {/* New Header */}
          <Box textAlign="center" mb={2} mt={4}>
            <Text
              fontSize="3xl"
              fontWeight="bold"
              color="white"
              letterSpacing="-3px"
            >
              dots
            </Text>
          </Box>
          {/* Updated ModalHeader as normal text */}
          <ModalHeader textAlign="center">Reconfirm your email to sign-in</ModalHeader>

          <ModalBody pb={6}>
            <Input
              placeholder="Email"
              value={emailForSignIn}
              onChange={(e) => setEmailForSignIn(e.target.value)}
              bgColor="white"
              borderRadius="full"
              color="black"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              bg="#FF0050"
              color="white"
              mr={3}
              onClick={handleEmailConfirm}
              _hover={{ bg: "#E6004A" }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AuthStateHandler;